<template>
  <vsm-menu :menu="menu">
    <template #after-nav>
      <vsm-mob>
        <div style="min-height: 200px; padding: 30px;font-size:13px">
          <!-- Themes -->
          <li>
            <ul>
              <li class="ulPadding">
                <button style="width:200px;margin-left:-20px" @click="changementHref('/Checkout')" class="buttonGetThemes"><icon style="margin-right:2px" class="fa fa-cart-shopping"></icon>{{ $t("checkout.header", {}, { locale: $i18n.locale }) }}</button>
              </li>
            </ul>
          </li>

          <!-- HOME -->
          <li>
            <ul>
              <li class="ulPadding">
                <a href="/" @click="changementHref('/')" class="button">{{ $t("sidebar.mainMenu", {}, { locale: $i18n.locale }) }}</a>
              </li>
            </ul>
          </li>

          <!-- LOGIN/SIGNUP -->
          <li>
            <ul>
              <li class="ulPadding">
                <a href="/Login" @click="changementHref('/Login')" class="button">{{ $t("login.header", {}, { locale: $i18n.locale }) }}</a>
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li class="ulPadding">
                <a href="/SignUp" @click="changementHref('/SignUp')" class="button">{{ $t("signUp.header", {}, { locale: $i18n.locale }) }}</a>
              </li>
            </ul>
          </li>

          <p style="font-size:16px"><u><b>{{ $t("mobileMenu.availableMethods", {}, { locale: $i18n.locale }) }}</b></u></p>
          <ul class="ulPadding">
            <!-- FPASNetflixServices -->
            <li><b>FPASNetflixServices</b></li>
            <ul>
              <li><a href="/FPASNetflixServices/SearchForPeopleApiV2" class="button" @click="changementHref('/FPASNetflixServices/SearchForPeopleApiV2')">SearchForPeopleApiV2</a></li>
            </ul>

            <!-- FPASGoogleServices -->
            <li><b>FPASGoogleServices</b></li>
            <li>
              <ul>
                <li>
                  {{ $t("sidebar.googleServices.youtube", {}, { locale: $i18n.locale }) }}
                  <ul>
                    <li><a href="/FPASGoogleServices/YouTube/SearchVideosApiV3" class="button" @click="changementHref('/FPASGoogleServices/YouTube/SearchVideosApiV3')">SearchVideosApiV3</a></li>
                    <li><a href="/FPASGoogleServices/YouTube/GetVideoReportReasonsApiV3" class="button" @click="changementHref('/FPASGoogleServices/YouTube/GetVideoReportReasonsApiV3')">GetVideoReportReasonsApiV3</a></li>
                    <li><a href="/FPASGoogleServices/YouTube/GetI18nLanguagesApiV3" class="button" @click="changementHref('/FPASGoogleServices/YouTube/GetI18nLanguagesApiV3')">GetI18nLanguagesApiV3</a></li>
                    <li><a href="/FPASGoogleServices/YouTube/GetVideoCaptionsTracksApiV3" class="button" @click="changementHref('/FPASGoogleServices/YouTube/GetVideoCaptionsTracksApiV3')">GetVideoCaptionsTracksApiV3</a></li>
                    <li><a href="/FPASGoogleServices/YouTube/DownloadVideoCaptionsApiV3" class="button" @click="changementHref('/FPASGoogleServices/YouTube/DownloadVideoCaptionsApiV3')">DownloadVideoCaptionsApiV3</a></li>
                    <li><a href="/FPASGoogleServices/YouTube/GetSubscriptionsApiV3" class="button" @click="changementHref('/FPASGoogleServices/YouTube/GetSubscriptionsApiV3')">GetSubscriptionsApiV3</a></li>
                  </ul>
                </li>
              </ul>
            </li>

            <!-- FPASPaypalServices -->
            <li><b>FPASPaypalServices</b></li>
            <li>
              <ul>
                <!-- Invoices -->
                <li>
                  {{ $t("sidebar.paypalServices.invoices", {}, { locale: $i18n.locale }) }}
                  <ul>
                    <li><a href="/FPASPayPalServices/Invoices/CreateAndSendInvoiceApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/CreateAndSendInvoiceApiV2')">CreateAndSendInvoiceApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/CreateInvoiceApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/CreateInvoiceApiV2')">CreateInvoiceApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/SendInvoiceApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/SendInvoiceApiV2')">SendInvoiceApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/DeleteInvoiceApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/DeleteInvoiceApiV2')">DeleteInvoiceApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/CancelSentInvoiceApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/CancelSentInvoiceApiV2')">CancelSentInvoiceApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/RefundInvoiceApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/RefundInvoiceApiV2')">RefundInvoiceApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/GetInvoiceDetailsApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/GetInvoiceDetailsApiV2')">GetInvoiceDetailsApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/GenerateInvoiceQRCodeApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/GenerateInvoiceQRCodeApiV2')">GenerateInvoiceQRCodeApiV2</a></li>
                    <li><a href="/FPASPayPalServices/Invoices/GenerateInvoiceNumberApiV2" class="button" @click="changementHref('/FPASPayPalServices/Invoices/GenerateInvoiceNumberApiV2')">GenerateInvoiceNumberApiV2</a></li>
                  </ul>
                </li>
                <!-- Transactions -->
                <li>
                  {{ $t("sidebar.paypalServices.transactions", {}, { locale: $i18n.locale }) }}
                  <ul>
                    <li><a href="/FPASPayPalServices/Transactions/ListTransactionsApiV1" class="button" @click="changementHref('/FPASPayPalServices/Transactions/ListTransactionsApiV1')">ListTransactionsApiV1</a></li>
                  </ul>
                </li>
              </ul>
            </li>

            <!-- FPASMiscServices -->
            <li><b>FPASMiscServices</b></li>
            <li>
              <ul>
                <!-- CountAPI -->
                <li>
                  {{ $t("sidebar.miscServices.countApi", {}, { locale: $i18n.locale }) }}
                  <ul>
                    <li><a href="/FPASMiscServices/CountAPI/GetCountApiValue" class="button" @click="changementHref('/FPASMiscServices/CountAPI/GetCountApiValue')">GetCountApiValue</a></li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <!-- Contact -->
          <p><u>{{ $t("sidebar.contact.header", {}, { locale: $i18n.locale }) }}</u></p>
          <ul class="ulPadding">
            <li>
              <ul>
                <li><a href="https://www.linkedin.com/in/anthony-boileau-7211b7225/" class="button">{{ $t("sidebar.contact.linkedIn", {}, { locale: $i18n.locale }) }}</a></li>
                <li><a href="https://github.com/AnthonyBoileau" class="button">{{ $t("sidebar.contact.github", {}, { locale: $i18n.locale }) }}</a></li>
                <li><a href="mailto:anthonyboileau@hotmail.com" class="button">{{ $t("sidebar.contact.mail", {}, { locale: $i18n.locale }) }}</a></li>
              </ul>
            </li>
          </ul>

          <!-- Options -->
          <p><u>{{ $t("sidebar.options", {}, { locale: $i18n.locale }) }}</u></p>
          <ul class="ulPadding">
            <!-- Languages -->
            <li>
              <b>{{ $t("sidebar.language", {}, { locale: $i18n.locale }) }}</b>
              <ul>
                <li><a href="/" class="button" @click="changerLangue('fr')">Français</a></li>
                <li><a href="/" class="button" @click="changerLangue('en-US')">English</a></li>
              </ul>
            </li>

            <!-- Themes -->
            <li>
              <b>{{ $t("sidebar.theme", {}, { locale: $i18n.locale }) }}</b>
              <ul>
                <li><a href="/" class="button" @click="changerThemes('FPASLightTheme')">{{ $t("sidebar.themes.light", {}, { locale: $i18n.locale }) }}</a></li>
                <li><a href="/" class="button" @click="changerThemes('FPASDarkTheme')">{{ $t("sidebar.themes.dark", {}, { locale: $i18n.locale }) }}</a></li>
                <li><a href="/" class="button" @click="changerThemes('FPASHalloweenEventTheme')">{{ $t("sidebar.themes.halloween", {}, { locale: $i18n.locale }) }}</a></li>
                <li><a href="/" class="button" @click="changerThemes('FPASChristmasEventTheme')">{{ $t("sidebar.themes.christmas", {}, { locale: $i18n.locale }) }}</a></li>
              </ul>
            </li>

            <!-- Musique -->
            <!-- <div v-if="isEvent">
              <li>
                {{ $t("sidebar.music.header", {}, { locale: $i18n.locale }) }}
                <ul>
                  On/Off
                  <li><a class="button" @click="changerMusique('on')">{{ $t("sidebar.music.on", {}, { locale: $i18n.locale }) }}</a></li>
                  <li><a class="button" @click="changerMusique('off')">{{ $t("sidebar.music.off", {}, { locale: $i18n.locale }) }}</a></li>
                </ul>
              </li>
            </div> -->
          </ul>
        </div>
      </vsm-mob>
    </template>
  </vsm-menu>
</template>

<script>
import { VsmMenu, VsmMob } from 'vue-stripe-menu'

export default {
  name: 'MobileMenu',
  components: {
    VsmMenu,
    VsmMob
  },
  data() {
    return {
      theme: '',
      isEvent: false,
      menu: []
    };
  },
  beforeCreate() {
    const themeCourant = localStorage.getItem('FPASCurrentTheme');
    if (themeCourant === 'FPASHalloweenEventTheme' || themeCourant === 'FPASChristmasEventTheme') {
      this.isEvent = true;
    } else {
      this.isEvent = false;
    }
  },
  created() {
    // TODO POUR LE SEO <-- LORSQU'UN HREF EST SÉLECTIONNÉ DIRECTEMENT DANS L'INSPECTEUR (VOIR HREF DANS LE TEMPLATE PLUS HAUT),
    // FAUDRAIT QUE ÇA AMÈNE À LA BONNE PAGE ET PAS JUSTE L'ACCUEIL

    this.theme = localStorage.getItem('FPASCurrentTheme');
    this.setterEvenement();
  },
  mounted() {
    var menuBackground = document.getElementsByClassName('vsm-menu');
    switch (this.theme) {
      case 'FPASDarkTheme': menuBackground[0].classList.add('headerDarkTheme');
        break;
      case 'FPASLightTheme': menuBackground[0].classList.add('headerLightTheme');
        break;
      case 'FPASHalloweenEventTheme': menuBackground[0].classList.add('headerHalloweenEvent');
        break;
      case 'FPASChristmasEventTheme': menuBackground[0].classList.add('headerChristmasEvent');
        break;
      case 'FPASGoldSpecialTheme-G0LD-V1P': menuBackground[0].classList.add('headerSpecialGoldTheme');
        break;
      case 'FPASSilverSpecialTheme-S1LV3R': menuBackground[0].classList.add('headerSpecialSilverTheme');
        break;
      case 'FPASBronzeSpecialTheme-BR0NZ3': menuBackground[0].classList.add('headerSpecialBronzeTheme');
        break;
      default: menuBackground[0].classList.add('headerLightTheme');
        break;
    }
  },
  methods: {
    setterEvenement() {
      /* Code doit être dupliqué (beforeCreate ne peut pas appeler une méthode) */
      const themeCourant = localStorage.getItem('FPASCurrentTheme');
      if (themeCourant === 'FPASHalloweenEventTheme' || themeCourant === 'FPASChristmasEventTheme') {
        this.isEvent = true;
      } else {
        this.isEvent = false;
      }
    },
    // Logique
    changerLangue(langue) {
      this.$emit('changementLangue', langue);
      this.fermerMenuMobile();
    },
    changerThemes(theme) {
      this.$emit('changementTheme', theme);
      this.fermerMenuMobile();
    },
    changerMusique(etat) {
      this.$emit('changementMusique', etat);
      this.fermerMenuMobile();
    },
    changementHref(composant) {
      this.$emit('changementHref', composant);
      this.fermerMenuMobile();
    },
    fermerMenuMobile() {
      document.querySelector('.vsm-mob-close').click();
    },
    // Styles
    stylesNavbarThemes() {
      this.$nextTick(() => {
        const navbar = document.getElementById('divNavbar');
        switch (this.theme) {
          case 'FPASLightTheme':
            navbar.classList.add('navbarLightTheme');
            break;
          case 'FPASDarkTheme':
            navbar.classList.add('navbarDarkTheme');
            break;
          case 'FPASHalloweenEventTheme':
            navbar.classList.add('navbarHalloweenEventTheme');
            break;
          case 'FPASChristmasEventTheme':
            navbar.classList.add('navbarChristmasEventTheme');
            break;
          default:
            navbar.classList.add('navbarLightTheme');
            break;
        }
      });
    },
    verificationEvenement() {
      this.$nextTick(() => {
        if (this.isEvent === false) {
          const btnMusique = document.getElementById('btnMusique');
          btnMusique.classList.add('transparence');
        }
      })
    }
  },
};
</script>

<style scoped>
/* Menu mobile */
li {
  list-style: none;
  margin-top: 10px;
}

.ulPadding {
  padding:0;
  margin-right:100%; /* Aligne les choix dans le menu mobile */
}

a {
  font-style: italic;
  font-weight: bold;
  margin-left:-15px;
  text-decoration: none;
}

.button {
  display: block;
  width: 190px;
  height: 25px;
  background: rgb(200, 200, 200);
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
}

/* ---- Navbar -------- */
.navbarCustom {
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

.navbarCustom a {
  float: left;
  font-size: 16px;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbarLightTheme {
  background-color: #a9a9ab;
}

.navbarLightTheme a,
.navbarDarkTheme a,
.btnLightTheme,
.btnDarkTheme,
.btnChristmasEventTheme,
.navbarChristmasEventTheme {
  color: white;
}

.navbarDarkTheme {
  background-color: rgb(53 53 53);
}

.navbarHalloweenEventTheme {
  background-image: linear-gradient(30deg, #ff8b00, #5b0101);
}

.navbarHalloweenEventTheme,
.btnHalloweenEventTheme,
.dropdownHalloweenEventTheme a {
  color: orange;
}

.navbarChristmasEventTheme {
  background-image: linear-gradient(30deg, rgba(14, 139, 27), red);
}
/* -------------------- */

.stylesDropdown {
  float: left;
  overflow: hidden;
}

.transparence {
  opacity: 0.5 !important;
}

.stylesDropdown .dropbtn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 15px 8px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.stylesDropdownContent {
  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.stylesDropdownContent a {
  float: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdownHalloweenEventTheme, .dropdownDarkTheme {
  background-color: #222020;
}

.dropdownLightTheme {
  background-color: #dddddd;
}

.dropdownChristmasEventTheme {
  background-color: #c70a0a;
}

.dropdownLightTheme a, .dropdownLightTheme p {
  color: black;
}

.dropdownDarkTheme a, .dropdownDarkTheme p,
.dropdownChristmasEventTheme a, .dropdownChristmasEventTheme p {
  color: white;
}

.stylesDropdownContent a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.espacementIcones {
  padding-left: 7px;
}

.vsm-menu {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}

.vsm-nav {
  margin: 0 10px;
}

.vsm-link-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .vsm-mob-show {
    display: block;
  }
  .vsm-mob-hide {
    display: none;
  }
  .vsm-mob-full {
    flex-grow: 1;
  }
}
</style>