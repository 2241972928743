<template>
  <div>
    <Documentation
      :title="'DownloadVideoCaptionsApiV3'"
      :codeExample="'await fpasGoogleServices.DownloadVideoCaptionsApiV3(ytCredentials, captionId, download);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'DownloadVideoCaptionsApiV3',
  components: {
    Documentation
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'ytCredentials', type: 'YTCredentials', description: this.$t('propsDesc.youtube.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'captionId', type: 'string', description: this.$t('propsDesc.youtube.captionTrackIdDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'download', type: 'YTDownloadCaptionsV3', description: this.$t('propsDesc.youtube.downloadCaptionsV3Desc', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('componentsDesc.downloadVideoCaptionsApiV3', {}, { locale: this.$i18n.locale });
  },
}
</script>