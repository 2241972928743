<template>
  <div>
    <Documentation
      :title="'GetVideoCaptionsTracksApiV3'"
      :codeExample="'await fpasGoogleServices.GetVideoCaptionsTracksApiV3(ytCredentials, parameters, videoId);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'GetVideoCaptionsTracksApiV3',
  components: {
    Documentation
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'ytCredentials', type: 'YTCredentials', description: this.$t('propsDesc.youtube.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'parameters', type: 'YTPartAndHlParameters', description: this.$t('propsDesc.youtube.partAndHlApiV3Desc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'videoId', type: 'string', description: this.$t('propsDesc.youtube.videoIdDesc', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('componentsDesc.getVideoCaptionsTracksApiV3', {}, { locale: this.$i18n.locale });
  },
}
</script>