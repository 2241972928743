<template>
  <div :class="validationEspacement()">
    <img
      v-if="estMobile"
      src="..\assets\img\FAANGPlusAPISimplifierLogo.png"
      alt="FAANGPlusApiSimplifier_MainLogo"
      class="mainLogo"
    >
    <div
      id="content"
      :class="gestionThemes('border')"
      style="margin-top:30px;padding-top:10px"
    >
      <div :class="gestionThemes('texte')">
        <p style="font-size:30px">
          <b>{{ $t("home.welcome", {}, { locale: $i18n.locale }) }}</b>
        </p><br>
        <p style="padding:20px;margin-top:-25px">{{ $t("home.packageDescription", {}, { locale: $i18n.locale }) }}</p>
      </div>
      <div style="height:80px" :class="gestionThemes('texte')">
        <p>{{ $t("home.links", {}, { locale: $i18n.locale }) }}</p>
        <a
          class="link"
          :class="gestionThemes('borderLink')"
          href="https://www.nuget.org/packages/FAANGPlusApiSimplifier/"
        >Nuget.org<i class="fa fa-arrow-up-right-from-square" style="margin-left:5px;width:10px" /></a>
      </div>
      <div :class="gestionThemes('texte')">
        <p class="marginTopElements">
          {{ $t("home.visitFor", {}, { locale: $i18n.locale }) }}
        </p>
        <ul style="list-style-type:square;text-align:left;width:50%;margin:auto;max-width:290px">
          <li>{{ $t("home.feature1", {}, { locale: $i18n.locale }) }}</li>
          <li>{{ $t("home.feature2", {}, { locale: $i18n.locale }) }}</li>
          <li>{{ $t("home.feature3", {}, { locale: $i18n.locale }) }}</li>
        </ul>
      </div>
      <div>
        <p style="padding:20px" :class="gestionThemes('texte')">{{ $t("home.sidebarExplanation", {}, { locale: $i18n.locale }) }}<br>{{ $t("home.specialThemes", {}, { locale: $i18n.locale }) }}<br><br>
          {{ $t("home.fpasAbbreviation", {}, { locale: $i18n.locale }) }}
        </p>
      </div>
      <div :class="gestionThemes('texte')">
        <b><p style="padding:20px">{{ $t("home.disclaimer", {}, { locale: $i18n.locale }) }}</p></b>
      </div>
    </div>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import UtilitiesLightTheme from '../utilities/UtilitiesLightTheme'
import ReusableCode from './composantsGlobal/ReusableCode.vue'

export default {
  name: 'HomeComponent',
  components: {
    ReusableCode
  },
  data() {
    return {
      estMobile: false,
    }
  },
  created() {
    this.theme = localStorage.getItem('FPASCurrentTheme');
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
    this.contentStyle();
    this.$refs.logic.setMaxHeight('home');
  },
  methods: {
    gestionThemes(typeBalise) {
      switch (typeBalise) {
        case 'border':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'projectBorderStylesDarkTheme';
            case 'FPASLightTheme': return 'projectBorderStylesLightTheme';
            case 'FPASHalloweenEventTheme': return 'projectBorderStylesHalloweenEvent';
            case 'FPASChristmasEventTheme': return 'projectBorderStylesChristmasEventGreen';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'projectBorderStylesSpecialGoldTheme';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'projectBorderStylesSpecialBronzeTheme';
            default: return 'projectBorderStylesLightTheme'
          }
        case 'texte':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'darkThemeTextColor';
            case 'FPASLightTheme': return 'lightThemeTextColor';
            case 'FPASHalloweenEventTheme': return 'halloweenEventTextColor';
            case 'FPASChristmasEventTheme': return 'christmasEventTextColor';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'goldSpecialThemeTextColor';
            case 'FPASSilverSpecialTheme-S1LV3R': return 'silverSpecialThemeTextColor';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'bronzeSpecialThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        case 'borderLink':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'darkThemeBorderLink';
            case 'FPASLightTheme': return 'lightThemeBorderLink';
            case 'FPASHalloweenEventTheme': return 'halloweenEventBorderLink';
            case 'FPASChristmasEventTheme': return 'christmasEventBorderLink';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'specialGoldThemeBorderLink';
            case 'FPASSilverSpecialTheme-S1LV3R': return 'specialSilverThemeBorderLink';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'specialBronzeThemeBorderLink';
            default: return 'lightThemeBorderLink'
          }
        default:
          // Thème invalide, donc l'alerte blanche est utilisée par défaut
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(5000, 'error',
            this.$t('errors.styleError', {}, { locale: this.$i18n.locale }), this.$t('errors.styleErrorDesc', {}, { locale: this.$i18n.locale }));
          break;
      }
    },
    contentStyle() {
      var content = document.getElementById('content');
      if (this.estMobile)
        content.classList.add('affichageContenuMobile');
      else
        content.classList.add('affichageContenu');
    },
    validationEspacement() {
      if (!this.estMobile)
        return 'espacementDesktop';
      return '';
    }
  }
}
</script>

<style scoped>
.mainLogo {
  width: 90%;
}

.link {
  text-decoration: none;
  color:inherit;
  padding: 12px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.darkThemeBorderLink {
  border: 3px solid white;
  background-color: #444242;
}

.lightThemeBorderLink {
  border: 3px solid #5a5a5a;
  background-color: #f9f9f9;
}

.specialSilverThemeBorderLink {
  border: 3px solid #5a5a5a;
  background-color: #f9f9f9a1;
}

.halloweenEventBorderLink {
  border: 3px solid darkorange;
  background-color: #4c3100;
}

.christmasEventBorderLink {
  border: 3px solid red;
  background-color: #ff00001f;
}

.specialGoldThemeBorderLink, .specialBronzeThemeBorderLink {
  border: 3px solid white;
  background-color: #fffdfd2b;
}
</style>