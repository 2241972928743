<template>
  <div style="display:flex" v-show="contentLoaded">
    <div class="centrerBoutonsCarousel">
      <!-- Bouton < desktop -->
      <button v-if="!hideLeftRightButtons && !estMobile" :class="gestionThemes('button')" @click="precedente()">←</button>
    </div>
    <transition-group
      name="fade"
      tag="div"
    >
      <div
        v-for="image in images"
        :key="image"
      >
        <img
          style="width:90%;height:auto"
          :src="currentImg"
          alt="image.png"
        >
      </div>
      <div v-if="estMobile">
        <!-- Boutons < et > (mobile) -->
        <button style="margin-right:20px" v-if="!hideLeftRightButtons" :class="gestionThemes('button')" @click="precedente()">←</button>
        <button v-if="!hideLeftRightButtons" :class="gestionThemes('button')" @click="prochaine()">→</button>
      </div>
      <p class="themeName">{{ currentName() }}</p>
    </transition-group>
    <div class="centrerBoutonsCarousel">
      <!-- Bouton > desktop -->
      <button v-if="!hideLeftRightButtons && !estMobile" :class="gestionThemes('button')" @click="prochaine()">→</button>
    </div>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import ReusableCode from './ReusableCode.vue';

export default {
  name: 'SliderComponent',
  components: {
    ReusableCode
  },
  props: {
    imagesInformation: {
      type: Array,
      default() {
        return []
      }
    },
    hideLeftRightButtons: {
      type: Boolean
    },
    automaticSlideShowActivated: {
      type: Boolean
    }
  },
  data() {
    return {
      timer: null,
      currentIndex: 0,
      compteur: 0,
      images: [],
      contentLoaded: false,
      nomImageAffiche: false,
      estMobile: false,
    };
  },
  computed: {
    imageCourante() {
      return this.images[Math.abs(this.currentIndex) % this.images.length].image;
    },
    currentImg() {
      const img = this.images[Math.abs(this.currentIndex) % this.images.length].image;
      return require('../../assets/img/themes/' + img)
    },
  },
  created() {
    this.images = this.imagesInformation;
  },
  mounted() {
    this.correctionImages();
    if (this.automaticSlideShowActivated) {
      this.startSlide();
    }
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
    this.contentLoaded = true;
  },
  methods: {
    prochaine() {
      this.currentIndex += 1;
    },
    precedente() {
      this.currentIndex -= 1;
    },
    correctionImages() {
      // Si une image est ajoutée dans le component parent, le nombre dans cpt > X doit être augmenté
      var images = document.getElementsByTagName('img');
      const arrayLength = images.length;
      for (var cpt = 0; cpt < arrayLength; cpt++) {
        if (cpt > 1) {
            if (images[cpt] !== undefined) {
                images[cpt].remove();
                cpt -= 1;
            }
        }
      }
    },
    startSlide() {
      this.timer = setInterval(this.prochaine, 3500);
    },
    gestionThemes(styleBalise) {
      if (this.contentLoaded)
        return this.$refs.logic.gestionThemes(styleBalise);
    },
    currentName() {
      const nom = this.images[Math.abs(this.currentIndex) % this.images.length].name;
      return nom;
    }
  },
};
</script>

<style scoped>
.tailleImage {
  width: 40%;
  height: auto;
}

.tailleBtn {
  width: 80px;
  margin-right: 20px;
  margin-left: 20px;
}

.centrerBoutonsCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  width: 60px;
}

.themeName {
  font-size: 30px;
  font-style: italic;
  font-family: auto;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  margin-top:5px;
}
</style>