<template>
  <div>
    <Documentation
      :title="'SearchForPeopleApiV2'"
      :codeExample="'fpasNetflixServices.SearchForPeopleApiV2(credentials, search);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'SearchForPeopleApiV2View',
  components: {
    Documentation
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'credentials', type: 'uNoGSNetflixCredentials', description: this.$t('propsDesc.netflix.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'search', type: 'NetflixSearchForPeopleApiV2', description: this.$t('propsDesc.netflix.searchForPeopleApiV2Desc', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('componentsDesc.searchForPeopleApiV2', {}, { locale: this.$i18n.locale });
  }
}
</script>