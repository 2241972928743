<template>
  <div v-show="contentLoaded">
    <div :class="gestionThemes('borderDeprecatedWarning')" id="deprecationWarningContent">
      <p style="padding:20px;font-style:italic">{{ $t("documentation.deprecationWarning", {}, { locale: $i18n.locale }) }}</p>
    </div>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import ReusableCode from './ReusableCode.vue';

export default {
  name: 'DeprecationWarning',
  components: {
    ReusableCode,
  },
  data() {
    return {
      contentLoaded: false,
      estMobile: false,
    }
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }

    this.contentLoaded = true;
    this.$nextTick(() => {
      this.contentStyle();
    })
  },
  methods: {
    gestionThemes(typeBalise) {
      if (this.contentLoaded)
        return this.$refs.logic.gestionThemes(typeBalise);
    },
    contentStyle() {
      if (this.contentLoaded) {
        var content = document.getElementById('deprecationWarningContent');
        if (this.estMobile)
          content.classList.add('affichageContenuMobile');
        else
          content.classList.add('affichageContenu');
      }
    },
  },
}
</script>