<template>
  <div>
    <Documentation
      :title="'DeleteInvoiceApiV2'"
      :codeExample="'await fpasPayPalServices.DeleteInvoiceApiV2(credentials, invoiceId);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'DeleteInvoiceApiV2View',
  components: {
    Documentation,
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'credentials', type: 'Credentials', description: this.$t('propsDesc.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'invoiceId', type: 'string', description: this.$t('propsDesc.paypalInvoiceIdDesc', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('deleteInvoiceApiV2.description', {}, { locale: this.$i18n.locale });
  },
}
</script>