<template>
  <div :class="validationEspacement()">
    <div
      id="signUpContent"
      :class="gestionThemes('border')"
      style="margin-top:30px;padding-top:10px"
    >
      <div style="text-align:center;justify-content: center;display: grid;margin:auto">
        <div>
          <p :class="gestionThemes('texte')" style="font-size:20px">
            <b>{{ $t("signUp.createAccount", {}, { locale: $i18n.locale }) }}</b>
          </p><br>
        </div>
        <div style="display:flex">
          <label class="lengthLabel" :class="gestionThemes('texte')">{{ $t("signUp.username", {}, { locale: $i18n.locale }) }}</label>
          <input :class="gestionThemes('input')" id="txtUsername" type="text">
        </div>
        <div style="display:flex">
          <label class="lengthLabel" :class="gestionThemes('texte')">{{ $t("signUp.email", {}, { locale: $i18n.locale }) }}</label>
          <input :class="gestionThemes('input')" id="txtEmail" type="text">
        </div>
        <div style="display:flex">
          <label class="lengthLabel" :class="gestionThemes('texte')">{{ $t("signUp.password", {}, { locale: $i18n.locale }) }}</label>
          <input :class="gestionThemes('input')" id="txtPassword" type="password">
        </div>
        <div style="display:flex">
          <label class="lengthLabel" :class="gestionThemes('texte')">{{ $t("signUp.confirmPassword", {}, { locale: $i18n.locale }) }}</label>
          <input :class="gestionThemes('input')" id="txtPasswordConfirmation" type="password">
        </div>
        <div style="padding:20px">
          <button :class="gestionThemes('button')" @click="createAccount()">{{ $t("signUp.create", {}, { locale: $i18n.locale }) }}</button>
        </div>
      </div>
    </div>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import ReusableCode from '../../../components/composantsGlobal/ReusableCode.vue';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { getDatabase, ref, set } from 'firebase/database';

export default {
  name: 'SignUp',
  components: {
    ReusableCode
  },
  data() {
    return {
      estMobile: false,
      contentLoaded: false,
    }
  },
  created() {
    this.theme = localStorage.getItem('FPASCurrentTheme');
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
    this.contentLoaded = true;
    this.$nextTick(() => {
      this.contentStyle();
    })
  },
  methods: {
    validationEspacement() {
      if (this.contentLoaded) {
        if (!this.estMobile)
          return 'espacementDesktop';
        return '';
      }
    },
    gestionThemes(typeBalise) {
      if (this.contentLoaded)
        return this.$refs.logic.gestionThemes(typeBalise);
    },
    contentStyle() {
      if (this.contentLoaded) {
        var content = document.getElementById('signUpContent');
        if (this.estMobile)
          content.classList.add('affichageContenuMobile');
        else
          content.classList.add('affichageContenu');
      }
    },
    changementHref(composant) {
      if (composant !== undefined && composant !== '' && composant !== null) {
        localStorage.setItem('FPASTempPath', composant);
        this.$router.push({ path: composant })
      }
    },
    emailValidation(email) {
      var emailRegex = /\S+@\S+\.\S+/;
      return emailRegex.test(email);
    },
    showAlert(alertType, errorMessage) {
      return this.$refs.logic.showAlert(alertType, errorMessage);
    },
    async createAccount() {
      // TODO: Un jour, envoyer un email pour activer le compte
      const errorType = 'error';

      // Username
      const username = document.getElementById('txtUsername').value;
      if (username === '') {
        this.showAlert(errorType, this.$t('errors.signup.usernameRequired',{},{ locale: this.$i18n.locale }));
        return;
      }

      // Email
      const email = document.getElementById('txtEmail').value;
      if (email === '') {
        this.showAlert(errorType, this.$t('errors.signup.emailRequired',{},{ locale: this.$i18n.locale }));
        return;
      } else if (this.emailValidation(email) === false) {
        this.showAlert(errorType, this.$t('errors.signup.invalidEmailFormat',{},{ locale: this.$i18n.locale }));
        return;
      }

      // Password
      const password = document.getElementById('txtPassword').value;
      if (password === '') {
        this.showAlert(errorType, this.$t('errors.signup.passwordRequired',{},{ locale: this.$i18n.locale }));
        return;
      } else if (password.length < 8) {
        this.showAlert(errorType, this.$t('errors.signup.passwordlengthInvalid',{},{ locale: this.$i18n.locale }));
        return;
      }

      // Confirm password
      const confirmation = document.getElementById('txtPasswordConfirmation').value;
      if (confirmation === '') {
        this.showAlert(errorType, this.$t('errors.signup.confirmationRequired',{},{ locale: this.$i18n.locale }));
        return;
      } else if (confirmation !== password) {
        this.showAlert(errorType, this.$t('errors.signup.confirmationMismatch',{},{ locale: this.$i18n.locale }));
        return;
      }

      // Setup de la BD et de l'authentification
      const currentDatabase = getDatabase();
      const authentication = getAuth();

      try {
        await createUserWithEmailAndPassword(authentication, email, password).then((auth) => {
          if (auth) {
            // Compte créé correctement. Création des données de base dans la BD
            const uid = auth.user.uid;
            set(ref(currentDatabase, 'FPASUser-' + uid), {
              Username: username,
              Email: email,
              TotalDonations: 0.00,
              AccountActivated: true,
              DarkThemeSubscription: false,
              CompleteBundleSubscription: false,
              BronzeThemeUnlocked: false,
              SilverThemeUnlocked: false,
              GoldThemeUnlocked: false,
              SubscriptionEndDate: ''
            });

            this.showAlert('success', this.$t('signUp.accountCreatedSuccessfully',{},{ locale: this.$i18n.locale }));
            this.changementHref('/Login');
          }
        })
      } catch (error) {
        this.showAlert(errorType, error);
      }
    }
  }
}
</script>

<style scoped>
.lengthLabel {
  width: 145px;
  text-align: end;
  margin-right: 10px;
}
</style>