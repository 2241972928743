<template>
  <div :class="validationEspacement()" v-show="contentLoaded">
    <DeprecationWarning v-if="isDeprecated" />
    <div
      id="documentationContent"
      :class="gestionThemes('border')"
      style="margin-top:30px;padding-top:10px"
    >
      <div>
        <b>
          <p class="title" :class="gestionThemes('texte')">{{ title }}</p>
        </b>
      </div>
      <div>
        <p :class="gestionThemes('texte')" style="margin-left:45px;margin-right:45px">{{ description }}</p>
      </div>
      <div :class="gestionThemes('texte')">
        <p style="font-size: 15px;font-weight: bold;text-decoration: underline;">{{ $t("documentation.syntax", {}, { locale: $i18n.locale }) }}</p>
      </div>
      <div :class="gestionThemes('codeExample')">
        <p :class="gestionThemes('texte')" style="margin:10px;word-wrap: break-word">{{ codeExample }}</p>
      </div>
      <div :class="gestionThemes('texte')">
        <p style="font-size: 15px;font-weight: bold;text-decoration: underline;">{{ $t("documentation.parameters", {}, { locale: $i18n.locale }) }}</p>
      </div>
      <div style="padding-bottom:20px">
        <div>
          <div
            v-for="(item, index) in necessaryProps"
            :key="index"
            style="margin-left:30px;margin-right:30px"
          >
            <div>
              <div v-if="index != 0">
                <hr style="border-color: lightgray; margin: 20px;">
              </div>
              <div style="display:flex;margin:20px" :class="gestionThemes('texte')">
                <p style="padding-top:4px"><b>{{ item.title }}</b></p>
                <p v-if="!estMobile" style="padding:4px" :class="gestionThemes('propTypeStyle')">{{ item.type }}</p>
                <p v-else style="padding:4px;font-size:11px;margin-top:20px" :class="gestionThemes('propTypeStyle')">{{ item.type }}</p>
              </div>
              <p style="margin:20px;text-align:left;margin-top:-15px" :class="gestionThemes('texte')">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import ReusableCode from './ReusableCode.vue';
import DeprecationWarning from './DeprecationWarning.vue';

export default {
    name: 'DocumentationComponent',
    components: {
      ReusableCode,
      DeprecationWarning
    },
    props: {
      title: {
          type: String,
          default: ''
      },
      codeExample: {
          type: String,
          default: '',
      },
      necessaryProps: [],
      description: {
        type: String,
        default: '',
      },
      isDeprecated: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        estMobile: false,
        contentLoaded: false,
      }
    },
    created() {
      this.theme = localStorage.getItem('FPASCurrentTheme');
    },
    mounted() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        this.estMobile = true;
      }

      this.contentLoaded = true;
      this.$nextTick(() => {
        this.contentStyle();
      })
    },
    methods: {
        gestionThemes(typeBalise) {
          if (this.contentLoaded)
            return this.$refs.logic.gestionThemes(typeBalise);
        },
        validationEspacement() {
          if (this.contentLoaded) {
            if (!this.estMobile)
              return 'espacementDesktop';
            return '';
          }
        },
        contentStyle() {
          if (this.contentLoaded) {
            var content = document.getElementById('documentationContent');
            if (this.estMobile)
              content.classList.add('affichageContenuMobile');
            else
              content.classList.add('affichageContenu');
          }
        },
    }
}
</script>

<style scoped>
.title {
  margin-top: 0;
  font-size: 20px;
  text-decoration: underline;
}
</style>