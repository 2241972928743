<template>
  <div>
    <Documentation
      :title="'CancelSentInvoiceApiV2'"
      :codeExample="'await fpasPayPalServices.CancelSentInvoiceApiV2(credentials, invoiceId, cancellationObject);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'CancelSentInvoiceApiV2View',
  components: {
    Documentation
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'credentials', type: 'Credentials', description: this.$t('propsDesc.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'invoiceId', type: 'string', description: this.$t('propsDesc.paypalInvoiceIdDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'cancellationObject', type: 'CancelInvoiceApiV2', description: this.$t('propsDesc.paypalCancellationObjectDesc', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('cancelSentInvoiceApiV2.description', {}, { locale: this.$i18n.locale });
  }
}
</script>