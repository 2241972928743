<template>
  <div>
    <div>
      <Header
        v-if="!estMobile"
        @changementHrefHeader="onItemClickMobile"
      />
      <div v-if="estMobile == false">
        <SidebarMenu
          id="sidebarMenu"
          :collapsed="false"
          :hideToggle="true"
          :menu="menu"
          style="max-width:230px"
          :showOneChild="true"
          @item-click="onItemClick"
        />
      </div>
      <div v-else>
        <MobileMenu
          @changementLangue="changerLangue"
          @changementTheme="changerTheme"
          @changementMusique="gestionMusiqueMenuMobile"
          @changementHref="onItemClickMobile"
        />
      </div>
    </div>
    <component
      :is="refreshCurrentView()"
      ref="composantActuel"
    />
  </div>
</template>

<script>
// ============== Components ===============//
import { SidebarMenu } from 'vue-sidebar-menu';
import { markRaw } from 'vue/dist/vue.esm-bundler.js';
import Home from '../../views/HomeView.vue';
import MobileMenu from '../../components/composantsGlobal/MobileMenu.vue';
import Header from '../../components/composantsGlobal/Header.vue';
// import Forum from '../../components/Forum.vue';

// Connected user
import LoginView from '../../views/connectedUser/login/LoginView.vue';
import SignUpView from '../../views/connectedUser/login/SignUpView.vue';
import CheckoutView from '../../views/connectedUser/CheckoutView.vue';

// PayPal
import CreateAndSendInvoiceApiV2 from '../../views/paypal/invoices/CreateAndSendInvoiceApiV2View';
import CreateInvoiceApiV2 from '../../views/paypal/invoices/CreateInvoiceApiV2View';
import SendInvoiceApiV2 from '../../views/paypal/invoices/SendInvoiceApiV2View';
import DeleteInvoiceApiV2 from '../../views/paypal/invoices/DeleteInvoiceApiV2View';
import CancelSentInvoiceApiV2 from '../../views/paypal/invoices/CancelSentInvoiceApiV2View';
import RefundInvoiceApiV2 from '../../views/paypal/invoices/RefundInvoiceApiV2View';
import GetInvoiceDetailsApiV2 from '../../views/paypal/invoices/GetInvoiceDetailsApiV2View';
import GenerateInvoiceQRCodeApiV2 from '../../views/paypal/invoices/GenerateInvoiceQRCodeApiV2View';
import GenerateInvoiceNumberApiV2 from '../../views/paypal/invoices/GenerateInvoiceNumberApiV2View';
import ListTransactionsApiV1 from '../../views/paypal/transactions/ListTransactionsApiV1View';

// YouTube
import GetVideoReportReasonsApiV3 from '../../views/google/youtube/GetVideoReportReasonsApiV3View';
import SearchVideosApiV3 from '../../views/google/youtube/SearchVideosApiV3View';
import GetI18nLanguagesApiV3 from '../../views/google/youtube/GetI18nLanguagesApiV3View';
import GetVideoCaptionsTracksApiV3 from '../../views/google/youtube/GetVideoCaptionsTracksApiV3View';
import DownloadVideoCaptionsApiV3 from '../../views/google/youtube/DownloadVideoCaptionsApiV3View';
import GetSubscriptionsApiV3 from '../../views/google/youtube/GetSubscriptionsApiV3View';

// CountAPI (Misc)
import GetCountApiValue from '../../views/misc/countApi/GetCountApiValueView';

// Netflix
import SearchForPeopleApiV2 from '../../views/netflix/SearchForPeopleApiV2View';
import SearchForTitlesApiV2 from '../../views/netflix/SearchForTitlesApiV2View';
import GetListOfGenresApiV2 from '../../views/netflix/GetListOfGenresApiV2View';
import GetListOfSupportedCountriesApiV2 from '../../views/netflix/GetListOfSupportedCountriesApiV2View';
// =========================================== //

// Sweet-alerts
import UtilitiesLightTheme from '../../utilities/UtilitiesLightTheme';
import UtilitiesDarkTheme from '../../utilities/UtilitiesDarkTheme';

// --------- Musiques ------------- //
// Halloween
// import HE_BGM1 from '../../assets/music/halloween/HE-alexander-nakarada-halloween-theme-1.mp3'
// import HE_BGM2 from '../../assets/music/halloween/HE-scott-buckley-maleficus.mp3'
// import HE_BGM3 from '../../assets/music/halloween/HE-wombat-noises-audio-dont-go-inside.mp3'

// // Noël
// import CE_BGM1 from '../../assets/music/christmas/CE-maxkomusic-christmas-is-coming.mp3'
// import CE_BGM2 from '../../assets/music/christmas/CE-punch-deck-snowfall.mp3'
// import CE_BGM3 from '../../assets/music/christmas/CE-scott-buckley-first-snow.mp3'
// -------------------------------- //

const routes = {
  '/': Home,
  // Connected user
  '/Login': LoginView,
  '/SignUp': SignUpView,
  '/Checkout': CheckoutView,
  // PAYPAL
  '/FPASPayPalServices/Invoices/CreateAndSendInvoiceApiV2': CreateAndSendInvoiceApiV2,
  '/FPASPayPalServices/Invoices/CreateInvoiceApiV2': CreateInvoiceApiV2,
  '/FPASPayPalServices/Invoices/SendInvoiceApiV2': SendInvoiceApiV2,
  '/FPASPayPalServices/Invoices/DeleteInvoiceApiV2': DeleteInvoiceApiV2,
  '/FPASPayPalServices/Invoices/CancelSentInvoiceApiV2': CancelSentInvoiceApiV2,
  '/FPASPayPalServices/Invoices/RefundInvoiceApiV2': RefundInvoiceApiV2,
  '/FPASPayPalServices/Invoices/GetInvoiceDetailsApiV2': GetInvoiceDetailsApiV2,
  '/FPASPayPalServices/Invoices/GenerateInvoiceQRCodeApiV2': GenerateInvoiceQRCodeApiV2,
  '/FPASPayPalServices/Invoices/GenerateInvoiceNumberApiV2': GenerateInvoiceNumberApiV2,
  '/FPASPayPalServices/Transactions/ListTransactionsApiV1': ListTransactionsApiV1,
  // YOUTUBE
  '/FPASGoogleServices/YouTube/SearchVideosApiV3': SearchVideosApiV3,
  '/FPASGoogleServices/YouTube/GetVideoReportReasonsApiV3': GetVideoReportReasonsApiV3,
  '/FPASGoogleServices/YouTube/GetI18nLanguagesApiV3': GetI18nLanguagesApiV3,
  '/FPASGoogleServices/YouTube/GetVideoCaptionsTracksApiV3': GetVideoCaptionsTracksApiV3,
  '/FPASGoogleServices/YouTube/DownloadVideoCaptionsApiV3': DownloadVideoCaptionsApiV3,
  '/FPASGoogleServices/YouTube/GetSubscriptionsApiV3': GetSubscriptionsApiV3,
  // COUNT-API
  '/FPASMiscServices/CountAPI/GetCountApiValue': GetCountApiValue,
  // NETFLIX
  '/FPASNetflixServices/SearchForPeopleApiV2': SearchForPeopleApiV2,
  '/FPASNetflixServices/SearchForTitlesApiV2': SearchForTitlesApiV2,
  '/FPASNetflixServices/GetListOfGenresApiV2': GetListOfGenresApiV2,
  '/FPASNetflixServices/GetListOfSupportedCountriesApiV2': GetListOfSupportedCountriesApiV2
};

const separator = {
  template: '<hr style="border-color: lightgray; margin: 20px;">',
};

const verticalSpacer = {
  template: '<div style="height:70px"> </div>',
};

const secondVerticalSpacer = {
  template: '<div style="height:170px"> </div>',
};

// FIREBASE
// import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
// import { getDatabase, ref, set } from 'firebase/database';

export default {
  name: 'SidebarOptions',
  components: {
    SidebarMenu,
    MobileMenu,
    Header,
  },
  props: {
    originalURL: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      menu: [
        {
          component: markRaw(verticalSpacer)
        },
        {
          header: this.$t('sidebar.mainMenu', {}, { locale: this.$i18n.locale }),
          hiddenOnCollapse: true,
        },
        {
          href: '/',
          title: this.$t('sidebar.home', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-home',
        },
        {
          title: 'FPASNetflixServices',
          child: [
            {
              title: 'SearchForPeopleApiV2',
              href: '/FPASNetflixServices/SearchForPeopleApiV2',
            },
            {
              title: 'SearchForTitlesApiV2',
              href: '/FPASNetflixServices/SearchForTitlesApiV2'
            },
            {
              title: 'GetListOfGenresApiV2',
              href: '/FPASNetflixServices/GetListOfGenresApiV2'
            },
            {
              title: 'GetListOfSupportedCountriesApiV2',
              href: '/FPASNetflixServices/GetListOfSupportedCountriesApiV2'
            }
          ]
        },
        {
          title: 'FPASGoogleServices',
          child: [
            {
              title: this.$t('sidebar.googleServices.youtube', {}, { locale: this.$i18n.locale }),
              child: [
                {
                  title: 'SearchVideosApiV3',
                  href: '/FPASGoogleServices/YouTube/SearchVideosApiV3',
                },
                {
                  title: 'GetVideoReportReasonsApiV3',
                  href: '/FPASGoogleServices/YouTube/GetVideoReportReasonsApiV3',
                },
                {
                  title: 'GetI18nLanguagesApiV3',
                  href: '/FPASGoogleServices/YouTube/GetI18nLanguagesApiV3',
                },
                {
                  title: 'GetVideoCaptionsTracksApiV3',
                  href: '/FPASGoogleServices/YouTube/GetVideoCaptionsTracksApiV3',
                },
                {
                  title: 'DownloadVideoCaptionsApiV3',
                  href: '/FPASGoogleServices/YouTube/DownloadVideoCaptionsApiV3',
                },
                {
                  title: 'GetSubscriptionsApiV3',
                  href: '/FPASGoogleServices/YouTube/GetSubscriptionsApiV3',
                }
              ]
            }
          ]
        },
        {
          title: 'FPASPayPalServices',
          child: [
            {
              title: this.$t('sidebar.paypalServices.invoices', {}, { locale: this.$i18n.locale }),
              icon: 'fa-solid fa-file-invoice',
              child: [
                {
                  title: 'CreateAndSendInvoiceApiV2',
                  href: '/FPASPayPalServices/Invoices/CreateAndSendInvoiceApiV2',
                },
                {
                  title: 'CreateInvoiceApiV2',
                  href: '/FPASPayPalServices/Invoices/CreateInvoiceApiV2',
                },
                {
                  title: 'SendInvoiceApiV2',
                  href: '/FPASPayPalServices/Invoices/SendInvoiceApiV2',
                },
                {
                  title: 'DeleteInvoiceApiV2',
                  href: '/FPASPayPalServices/Invoices/DeleteInvoiceApiV2',
                },
                {
                  title: 'CancelSentInvoiceApiV2',
                  href: '/FPASPayPalServices/Invoices/CancelSentInvoiceApiV2',
                },
                {
                  title: 'RefundInvoiceApiV2',
                  href: '/FPASPayPalServices/Invoices/RefundInvoiceApiV2',
                },
                {
                  title: 'GetInvoiceDetailsApiV2',
                  href: '/FPASPayPalServices/Invoices/GetInvoiceDetailsApiV2',
                },
                {
                  title: 'GenerateInvoiceQRCodeApiV2',
                  href: '/FPASPayPalServices/Invoices/GenerateInvoiceQRCodeApiV2',
                },
                {
                  title: 'GenerateInvoiceNumberApiV2',
                  href: '/FPASPayPalServices/Invoices/GenerateInvoiceNumberApiV2',
                },
              ]
            },
            {
              title: this.$t('sidebar.paypalServices.transactions', {}, { locale: this.$i18n.locale }),
              icon: 'fa-solid fa-file-invoice-dollar',
              child: [
                {
                  title: 'ListTransactionsApiV1',
                  href: '/FPASPayPalServices/Transactions/ListTransactionsApiV1',
                }
              ]
            }
          ]
        },
        {
          title: 'FPASMiscServices',
          child: [
            {
              title: this.$t('sidebar.miscServices.countApi', {}, { locale: this.$i18n.locale }),
              child: [
                {
                  title: 'GetCountApiValue',
                  href: '/FPASMiscServices/CountAPI/GetCountApiValue',
                },
              ]
            }
          ]
        },
        // Todo: One day
        // {
        //   href: '/Forum',
        //   title: this.$t('sidebar.blog', {}, { locale: this.$i18n.locale }),
        //   icon: 'fa fa-comments',
        //   disabled: true, // Modifier lorsque nécessaire
        // },
        {
          component: markRaw(separator),
        },
        {
          title: this.$t('sidebar.contact.header', {}, { locale: this.$i18n.locale }),
          icon: 'fa-regular fa-comment',
          child: [
            {
              title: this.$t('sidebar.contact.linkedIn', {}, { locale: this.$i18n.locale }),
              icon: 'fa-brands fa-linkedin',
              contact: 'linkedIn'
            },
            {
              title: this.$t('sidebar.contact.github', {}, { locale: this.$i18n.locale }),
              icon: 'fa-brands fa-github',
              contact: 'github'
            },
            {
              title: this.$t('sidebar.contact.mail', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-envelope',
              contact: 'mail'
            }
          ]
        },
        {
          title: this.$t('sidebar.options', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-gear',
          child: [
            {
              title: this.$t('sidebar.language', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-globe',
              child: [
                {
                  title: 'Français',
                  abbreviation: 'fr'
                },
                {
                  title: 'English',
                  abbreviation: 'en-US'
                }
              ]
            },
            {
              title: this.$t('sidebar.theme', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-brush',
              child: [
                {
                  title: this.$t('sidebar.themes.light', {}, { locale: this.$i18n.locale }),
                  icon: 'fa fa-sun',
                  theme: 'FPASLightTheme',
                },
                {
                  title: this.$t('sidebar.themes.dark', {}, { locale: this.$i18n.locale }),
                  icon: 'fa fa-moon',
                  theme: 'FPASDarkTheme',
                  disabled: this.darkThemeDisabled,
                  badge: {
                    class: this.darkThemeDisabled ? 'fa fa-lock' : '',
                  }
                },
                {
                  title: this.$t('sidebar.themes.preciousMetalsThemes.preciousMetalsHeader', {}, { locale: this.$i18n.locale }),
                  icon: 'fa fa-gem',
                  disabled: this.exclusiveThemesCanBeOpened,
                  child: [
                    {
                      title: this.$t('sidebar.themes.preciousMetalsThemes.goldTheme', {}, { locale: this.$i18n.locale }),
                      icon: 'fa-solid fa-crown',
                      theme: 'FPASGoldSpecialTheme-G0LD-V1P',
                      disabled: this.goldThemeDisabled,
                      badge: {
                        class: this.goldThemeDisabled ? 'fa fa-lock' : '',
                      }
                    },
                    // TODO: À REMETTRE!!!
                    // {
                    //   title: this.$t('sidebar.themes.preciousMetalsThemes.goldPinkTheme', {}, { locale: this.$i18n.locale }),
                    //   icon: 'fa-solid fa-crown',
                    //   theme: 'FPASGoldPinkSpecialTheme-G0LD-Pink',
                    //   disabled: this.goldPinkThemeDisabled,
                    //   badge: {
                    //     class: this.goldPinkThemeDisabled ? 'fa fa-lock' : '',
                    //   }
                    // },
                    {
                      title: this.$t('sidebar.themes.preciousMetalsThemes.silverTheme', {}, { locale: this.$i18n.locale }),
                      icon: 'fa-solid fa-star',
                      theme: 'FPASSilverSpecialTheme-S1LV3R',
                      disabled: this.silverThemeDisabled,
                      badge: {
                        class: this.silverThemeDisabled ? 'fa fa-lock' : '',
                      }
                    },
                    {
                      title: this.$t('sidebar.themes.preciousMetalsThemes.bronzeTheme', {}, { locale: this.$i18n.locale }),
                      icon: 'fa-solid fa-star',
                      theme: 'FPASBronzeSpecialTheme-BR0NZ3',
                      disabled: this.bronzeThemeDisabled,
                      badge: {
                        class: this.bronzeThemeDisabled ? 'fa fa-lock' : '',
                      }
                    },
                  ]
                },
                {
                  component: markRaw(separator),
                },
                {
                  title: this.$t('sidebar.themes.halloween', {}, { locale: this.$i18n.locale }),
                  icon: 'fa-solid fa-ghost',
                  theme: 'FPASHalloweenEventTheme',
                  disabled: this.halloweenEventThemeDisabled,
                  badge: {
                    class: this.halloweenEventThemeDisabled ? 'fa fa-lock' : '',
                  }
                },
                {
                  title: this.$t('sidebar.themes.christmas', {}, { locale: this.$i18n.locale }),
                  icon: 'fa-solid fa-candy-cane',
                  theme: 'FPASChristmasEventTheme',
                  disabled: this.christmasEventThemeDisabled,
                  badge: {
                    class: this.christmasEventThemeDisabled ? 'fa fa-lock' : '',
                  }
                },
              ]
            },
          ]
        },
        // {
        //   title: this.$t('sidebar.music.header', {}, { locale: this.$i18n.locale }),
        //   icon: 'fa fa-music',
        //   disabled: !this.isEvent,
        //   child: [
        //     {
        //       title: this.$t('sidebar.music.on', {}, { locale: this.$i18n.locale }),
        //       icon: 'fa fa-volume-high',
        //       music: 'on',
        //     },
        //     {
        //       title: this.$t('sidebar.music.off', {}, { locale: this.$i18n.locale }),
        //       icon: 'fa fa-volume-xmark',
        //       music: 'off',
        //     }
        //   ]
        // },
        {
          component: markRaw(secondVerticalSpacer)
        },
        {
          title: this.$t('sidebar.note.copyright', {}, { locale: this.$i18n.locale })
        }
      ],
      currentPath: window.location.hash,
      urlChoisi: '',
      href: '',
      theme: '',
      estMobile: false,
      oldPath: '',
      audio: null,
      timer: '',
      firstLoop: true,
      musicStopped: false,
      isEvent: false,
      musics: [],
      currentTrack: 0,
      numberOfMusics: 0,
      idAncienInterval: -1,
      dureePopup: 5000,
      darkThemeDisabled: null,
      halloweenEventThemeDisabled: null,
      christmasEventThemeDisabled: null,
      goldThemeDisabled: null,
      silverThemeDisabled: null,
      bronzeThemeDisabled: null,
      exclusiveThemesCanBeOpened: null
    };
  },
  async beforeCreate() {
    const themeCourant = localStorage.getItem('FPASCurrentTheme');
    if (themeCourant === 'FPASHalloweenEventTheme' || themeCourant === 'FPASChristmasEventTheme') {
      this.isEvent = true;
    }

    // Gestion de la disponibilité des thèmes (TODO: AJOUTER UN CADENAS À CÔTÉ DE CEUX DISABLED)
    this.darkThemeDisabled = true;
    this.halloweenEventThemeDisabled = true;
    this.christmasEventThemeDisabled = true;
    this.goldThemeDisabled = true;
    this.silverThemeDisabled = true;
    this.bronzeThemeDisabled = true;
    this.exclusiveThemesCanBeOpened = this.goldThemeDisabled && this.silverThemeDisabled && this.bronzeThemeDisabled;

    // FIREBASE FONCTIONNE DANS LE BEFORECREATE
    // // Setup de la BD et de l'authentification
    //   const currentDatabase = getDatabase();
    //   const authentication = getAuth();

    // await createUserWithEmailAndPassword(authentication, 'Amogus-BeforeCreate@hotmail.com', '289983298QWER!').then((auth) => {
    //       if (auth) {
    //         // Compte créé correctement. Création des données de base dans la BD
    //         const uid = auth.user.uid;
    //         set(ref(currentDatabase, 'FPASUser-' + uid), {
    //           Username: 'TEST',
    //           Email: 'Amogus-BeforeCreate@hotmail.com',
    //           TotalDonations: 0.00,
    //           AccountActivated: true,
    //           DarkThemeSubscription: false,
    //           CompleteBundleSubscription: false,
    //           BronzeThemeUnlocked: false,
    //           SilverThemeUnlocked: false,
    //           GoldThemeUnlocked: false,
    //           SubscriptionEndDate: ''
    //         });
    //       }
    // })

  },
  created() {
    this.theme = localStorage.getItem('FPASCurrentTheme');

    // Loading des musiques si un event a lieu
    this.loadMusic(this.theme);

    // let popupAnsweredLocalStorage;
    // switch (this.theme) {
    //   case 'FPASHalloweenEventTheme':
    //     // Localstorage est une string. Évaluation du bool à partir de celle-ci (conversion)
    //     popupAnsweredLocalStorage = (localStorage.getItem('FPASpopupHalloweenEventAnswered') === 'true');
    //     if (popupAnsweredLocalStorage !== true) {
    //       this.CreerQuestionSwal(this.$t('eventPopup.question',{},{ locale: this.$i18n.locale }), '#FF8C00', '#000000',
    //         '#FF8C00', this.$t('eventPopup.oui',{},{ locale: this.$i18n.locale }), this.$t('eventPopup.non',{},{ locale: this.$i18n.locale }))
    //     }
    //     break;
    //   case 'FPASChristmasEventTheme':
    //     popupAnsweredLocalStorage = (localStorage.getItem('FPASpopupChristmasEventAnswered') === 'true');
    //     if (popupAnsweredLocalStorage !== true) {
    //       this.CreerQuestionSwal(this.$t('eventPopup.question',{},{ locale: this.$i18n.locale }), '#11751C', '#F30804',
    //         '#000000', this.$t('eventPopup.oui',{},{ locale: this.$i18n.locale }), this.$t('eventPopup.non',{},{ locale: this.$i18n.locale }))
    //     }
    //     break;
    //   default:
    //     break;
    // }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash;
    });
    this.stylesSidebar();

    // Gestion de la sidebar selon l'appareil utilisé
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
      this.dureePopup = 2000; // Pour pas prendre la moitié de l'écran trop longtemps
    }

    // Changer la page selon l'URL spécifié
    if (this.originalURL !== '') {
      if (!isMobile) {
        const url = {
          href: this.originalURL
        }
        this.onItemClickMobile(url);
      } else {
        this.onItemClickMobile(this.originalURL);
      }
    }
  },
  methods: {
    onItemClickMobile(item) {
      this.onItemClick(new Event('click'), item);
    },
    onItemClick(event, item) {
      // Affectation différente en desktop VS mobile
      if (!this.estMobile) {
        this.href = item.href;
      } else {
        this.href = item;
      }

      // Changement de langue
      if (item.abbreviation !== undefined) {
        this.changerLangue(item.abbreviation);
      }

      // Changement de thèmes
      if (item.theme !== undefined) {
        this.changerTheme(item.theme);
      }

      // Changement de musique
      if (item.music === 'on') {
        this.startMusic();
      } else if (item.music === 'off') {
        this.stopMusic();
      }

      // Gestion de contact
      if (item.contact !== undefined) {
        this.gestionContact(item.contact);
      }

      // Permet de faire un refresh dans le :is pour changer de page
      this.refreshCurrentView();
    },
    refreshCurrentView() {
      // Si c'est un refresh, prendre le href du localstorage
      const hrefTemp = localStorage.getItem('FPASTempPath');

      // Todo: Faire un array qui check avec "contains" si c'est dispo au lieu de spammer ||
      if (this.href === '' || hrefTemp === '/SignUp' || hrefTemp === '/Login' || hrefTemp === '/' || hrefTemp === '/Checkout') {
        if (this.href === '')
          this.href = localStorage.getItem('FPASpath');
        else
          this.href = localStorage.getItem('FPASTempPath');
        localStorage.setItem('FPASTempPath', '');
      }

      if (this.href !== undefined && this.href !== '' && this.href !== null) {
        this.$router.push({ path: this.href })
        localStorage.setItem('FPASpath', this.href);
      }
      this.currentPath = this.href;
      if (this.currentPath === undefined) {
        return routes[this.oldPath || '/'];
      }
      this.oldPath = this.currentPath;
      return routes[this.currentPath || '/'];
    },
    stylesSidebar() {
      var sidebar = document.getElementById('sidebarMenu');
      // todo switch
      switch (this.theme) {
        case 'FPASDarkTheme':
        case 'FPASHalloweenEventTheme':
          sidebar.classList.add('sidebarDarkTheme');
          break;
        case 'FPASChristmasEventTheme':
          sidebar.classList.add('sidebarChristmasEvent');
          break;
        case 'FPASGoldSpecialTheme-G0LD-V1P':
          sidebar.classList.add('sidebarGoldSpecialTheme');
          break;
        case 'FPASGoldPinkSpecialTheme-G0LD-Pink':
          sidebar.classList.add('sidebarGoldPinkSpecialTheme');
          break;
        case 'FPASSilverSpecialTheme-S1LV3R':
          sidebar.classList.add('sidebarSilverSpecialTheme');
          break;
        case 'FPASBronzeSpecialTheme-BR0NZ3':
          sidebar.classList.add('sidebarBronzeSpecialTheme')
          break;
        case 'FPASLightTheme':
        default:
          sidebar.classList.add('sidebarLightTheme');
          break;
      }
    },
    gestionMusiqueMenuMobile(etat) {
      switch (etat) {
        case 'on':
          this.startMusic();
          break;
        case 'off':
          this.stopMusic();
          break;
        default:
          this.stopMusic();
          break;
      }
    },
    loadMusic(theme) {
      // Code conservé au cas
      switch (theme) {
        case 'FPASHalloweenEventTheme':
          // this.musics.push({ musique: HE_BGM1, duree: 121000 }); // 1:59
          // this.musics.push({ musique: HE_BGM2, duree: 385000 }); // 6:25
          // this.musics.push({ musique: HE_BGM3, duree: 170000 }); // 2:50
          break;
        case 'FPASChristmasEventTheme':
          // this.musics.push({ musique: CE_BGM1, duree: 114000 }); // 1:54
          // this.musics.push({ musique: CE_BGM2, duree: 208000 }); // 3:28
          // this.musics.push({ musique: CE_BGM3, duree: 213000 }); // 3:33
          break;
        default:
          // Rien en dark ou light
          break;
      }
      this.numberOfMusics = this.musics.length;
    },
    changerLangue(abbreviation) {
      this.$i18n.locale = abbreviation;
      localStorage.setItem('FPASCurrentLang', this.$i18n.locale);
      window.location.reload();
    },
    changerTheme(theme) {
      localStorage.setItem('FPASCurrentTheme', theme);
      window.location.reload();
    },
    gestionContact(contact) {
      switch (contact) {
        case 'linkedIn':
          window.open('https://www.linkedin.com/in/anthony-boileau-7211b7225/', '_blank').focus();
          break;
        case 'github':
          window.open('https://github.com/AnthonyBoileau', '_blank').focus();
          break;
        case 'mail':
          var mail = document.createElement('a');
          mail.href = 'mailto:anthonyboileau@hotmail.com';
          mail.click();
          break;
        default: // Rien faire en cas d'erreur
          break;
      }
    },
    startMusic() {
      // TODO: Ajouter une validation du user en mobile
      // TODO: Corriger bugs avec on et off
      // TODO: Ajouter validation des dates (quand tout est fini)
      //      Pour les dates, quand l'event débute, si les popups answered du localstorage sont à true, remettre à false
      // Today, most desktop browsers will always allow web pages to begin <video> or <audio> playback via JavaScript without user interaction.
      // Most mobile browsers, however, require an explicit user gesture before JavaScript-initiated playback can occur.
      // This helps ensure that mobile users, many of whom pay for bandwidth or who might be in a public environment, don’t accidentally start downloading and playing media without explicitly interacting with the page.
      this.musicStopped = false;
      if (this.firstLoop === true) {
        switch (this.theme) {
          case 'FPASHalloweenEventTheme':
            this.gestionMusiqueHalloween();
            break;
          case 'FPASChristmasEventTheme':
            this.gestionMusiqueChristmas();
            break;
          default:
            break;
        }
        this.audio.play();
      }
      this.loop();
    },
    loop() {
      // Premier tour
      if (this.firstLoop !== true) {
        this.currentTrack += 1;
        clearInterval(this.idAncienInterval);
      }

      // Répétition
      if (this.musics[this.currentTrack].duree !== undefined)
        this.idAncienInterval = setInterval(this.loop, this.musics[this.currentTrack].duree);
      if (this.firstLoop === false && this.musicStopped !== true) {
        this.audio = new Audio(this.musics[this.currentTrack].musique)
        this.audio.pause();
        this.audio.currentTime = 0;
        this.audio.play();

        this.afficherPopup(this.currentTrack);
        // Reset
        if (this.numberOfMusics - 1 === this.currentTrack) {
          this.currentTrack = -1; // -1 puisque +1 est effectué dans le loop
        }
      }
      this.firstLoop = false;
    },
    stopMusic() {
      clearInterval(this.idAncienInterval);
      this.audio.pause();
      this.audio.currentTime = 0;
      this.musicStopped = true;
      this.firstLoop = true;
      this.currentTrack = 0;
    },
    gestionMusiqueHalloween() {
      this.audio = new Audio(this.musics[this.currentTrack].musique)
      this.creerAlerteSwalInfo();
    },
    gestionMusiqueChristmas() {
      this.audio = new Audio(this.musics[this.currentTrack].musique)
      this.creerAlerteSwalInfo();
    },
    afficherPopup(currentTrack) {
      switch (this.theme) {
        case 'FPASHalloweenEventTheme':
          switch (currentTrack) {
            case 0:
              UtilitiesDarkTheme.CreerAlerteSwalNoQuestion(this.dureePopup, 'info', this.$t('halloweenEvent.BGM1',{},{ locale: this.$i18n.locale }),
                this.$t('halloweenEvent.BGM1Info',{},{ locale: this.$i18n.locale }));
              break;
            case 1:
              UtilitiesDarkTheme.CreerAlerteSwalNoQuestion(this.dureePopup, 'info', this.$t('halloweenEvent.BGM2',{},{ locale: this.$i18n.locale }),
                this.$t('halloweenEvent.BGM2Info',{},{ locale: this.$i18n.locale }));
              break;
            default:
              break;
          }
          break;
        case 'FPASChristmasEventTheme':
          switch (currentTrack) {
            case 0:
              UtilitiesLightTheme.CreerAlerteSwalNoQuestion(this.dureePopup, 'info', this.$t('christmasEvent.BGM1',{},{ locale: this.$i18n.locale }),
                this.$t('christmasEvent.BGM1Info',{},{ locale: this.$i18n.locale }));
              break;
            case 1:
              UtilitiesLightTheme.CreerAlerteSwalNoQuestion(this.dureePopup, 'info', this.$t('christmasEvent.BGM2',{},{ locale: this.$i18n.locale }),
                this.$t('christmasEvent.BGM2Info',{},{ locale: this.$i18n.locale }));
              break;
            case 2:
              UtilitiesLightTheme.CreerAlerteSwalNoQuestion(this.dureePopup, 'info', this.$t('christmasEvent.BGM3',{},{ locale: this.$i18n.locale }),
                this.$t('christmasEvent.BGM3Info',{},{ locale: this.$i18n.locale }));
              break;
            case 3:
              UtilitiesLightTheme.CreerAlerteSwalNoQuestion(this.dureePopup, 'info', this.$t('christmasEvent.BGM4',{},{ locale: this.$i18n.locale }),
                this.$t('christmasEvent.BGM4Info',{},{ locale: this.$i18n.locale }));
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    creerAlerteSwalInfo() {
      switch (this.theme) {
        case 'FPASLightTheme':
        case 'FPASChristmasEventTheme':
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(
            this.dureePopup, 'info', this.$t('christmasEvent.BGM1',{},{ locale: this.$i18n.locale }),
            this.$t('christmasEvent.BGM1Info',{},{ locale: this.$i18n.locale }));
          break;
        case 'FPASDarkTheme':
        case 'FPASHalloweenEventTheme':
          UtilitiesDarkTheme.CreerAlerteSwalNoQuestion(
            this.dureePopup, 'info', this.$t('halloweenEvent.BGM1',{},{ locale: this.$i18n.locale }),
            this.$t('halloweenEvent.BGM1Info',{},{ locale: this.$i18n.locale }));
          break;
        default:
          break;
      }
    },
    CreerQuestionSwal(question, couleurBtnConfirm, couleurBtnCancel,
      couleurTexte, texteConfirmation, texteAnnulation) {
      this.$swal({
        title: question,
        position: 'top-start',
        icon: 'question',
        toast: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: couleurBtnConfirm,
        cancelButtonColor: couleurBtnCancel,
        color: couleurTexte,
        confirmButtonText: texteConfirmation,
        cancelButtonText: texteAnnulation,
        width: localStorage.getItem('FPASSwalLength')
      }).then((result) => {
        // Popup répondu + set du localstorage pour éviter le spam
        if (result.isConfirmed === true) {
          this.startMusic();
        }
        switch (this.theme) {
          case 'FPASHalloweenEventTheme':
            localStorage.setItem('FPASpopupHalloweenEventAnswered', true);
            break;
          case 'FPASChristmasEventTheme':
            localStorage.setItem('FPASpopupChristmasEventAnswered', true);
            break;
          default:
            break;
        }
      })
    },
  }
}
</script>

<style scoped>
.sidebarDarkTheme {
  background-color: #2a2a2e;
  background-image: linear-gradient(90deg, #262626, #333)
}

.sidebarLightTheme {
  background-color: #aeaeaea6;
}

.sidebarHalloweenEvent {
  background-color: rgb(236, 132, 5);
}

.sidebarChristmasEvent {
  background-color: rgb(243 8 4 / 100%);
}

</style>