<template>
  <div>
    <div v-if="theme == 'FPASChristmasEventTheme'">
      <Snowfall />
    </div>
    <div v-if="theme == 'FPASHalloweenEventTheme'">
      <RainfallEffect />
    </div>
    <SidebarOptions :originalURL="originalURL" />
    <Footer v-if="estMobile" />
  </div>
</template>

<script>
import SidebarOptions from './components/composantsGlobal/SidebarOptions.vue';
import Footer from './components/composantsGlobal/Footer.vue';
import './assets/styles/SidebarMenu.css';
import './assets/styles/FAANGPlusApiSimplifier.css';
import './assets/styles/_variables.scss';
import './assets/styles/css-variables.scss';
import './assets/styles/helper.scss';
import './assets/styles/index.scss';
import './assets/styles/menu.scss';
import './assets/styles/mob.scss';
import UtilitiesLightTheme from '../src/utilities/UtilitiesLightTheme';
import UtilitiesDarkTheme from '../src/utilities/UtilitiesDarkTheme';
import Snowfall from './components/composantsGlobal/christmasEvent/SnowfallEffect.vue';
import RainfallEffect from './components/composantsGlobal/halloweenEvent/RainfallEffect.vue';

export default {
  name: 'App',
  components: {
    // Header,
    SidebarOptions,
    Snowfall,
    RainfallEffect,
    Footer
  },
  data() {
    return {
      theme: '',
      FPASpopupHalloweenEventAnswered: false,
      FPASpopupChristmasEventAnswered: false,
      estMobile: false,
      originalURL: ''
    };
  },
  created() {
    const originalURL = window.location.href;
    const specifiedURL = originalURL.replace(window.location.origin, '');
    this.originalURL = specifiedURL;

    try {
      // Event qui check si le browser ferme ou non
      window.addEventListener('beforeunload', this.browserClosing());

      this.$i18n.locale = localStorage.getItem('FPASCurrentLang');
      this.theme = localStorage.getItem('FPASCurrentTheme');
      this.FPASpopupHalloweenEventAnswered = localStorage.getItem('FPASpopupHalloweenEventAnswered');
      this.FPASpopupChristmasEventAnswered = localStorage.getItem('FPASpopupChristmasEventAnswered');

      // Si le localstorage retourne null, mettre le thème light par défaut pour pas briser le site
      if (this.theme === null) {
        localStorage.setItem('FPASCurrentTheme', 'FPASLightTheme');
        this.theme = 'FPASLightTheme';
      }

      // Si le localstorage retourne une valeur vide
      if (this.theme === '') {
        localStorage.setItem('FPASCurrentTheme', 'FPASLightTheme');
        this.theme = 'FPASLightTheme';
      }
      if (this.FPASpopupHalloweenEventAnswered === null) {
        localStorage.setItem('FPASpopupHalloweenEventAnswered', false);
      }
      if (this.FPASpopupChristmasEventAnswered === null) {
        localStorage.setItem('FPASpopupChristmasEventAnswered', false);
      }
    } catch (e) {
      this.notifierErreurLocalStorage();
    }
  },
  mounted() {
    var htmlElement = document.querySelector('html');
    switch (this.theme) {
      case 'FPASDarkTheme':
      case 'FPASHalloweenEventTheme':
        htmlElement.classList.add('darkTheme');
        break;
      case 'FPASLightTheme':
      case 'FPASChristmasEventTheme':
        htmlElement.classList.add('lightTheme');
        break;
      case 'FPASGoldSpecialTheme-G0LD-V1P':
        htmlElement.classList.add('goldTheme-GOLD');
        break;
      case 'FPASSilverSpecialTheme-S1LV3R':
        htmlElement.classList.add('silverTheme-SILVER');
        break
      case 'FPASBronzeSpecialTheme-BR0NZ3':
        htmlElement.classList.add('bronzeTheme-BR0NZ3');
        break
      default:
        htmlElement.classList.add('lightTheme');
        break;
    }

    this.gestionCouleurIconesSidebar();
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
      // htmlElement.classList.add('augmentationWidth');
    } else {
      htmlElement.classList.add('maxHeight');
    }
    this.loadPath();
  },
  methods: {
    browserClosing() {
      // TODO: AJOUTER UN CHECK POUR PAS QUE ÇA SET LORSQUE LES THÈMES/LANGUES CHANGENT

      // Déconnexion automatique
      localStorage.setItem('FPASUserUID', '');
    },
    gestionCouleurIconesSidebar() {
      const sidebar = document.getElementsByClassName('v-sidebar-menu')[0];
      const icones = document.getElementsByClassName('vsm--icon');
      const headers = document.getElementsByClassName('vsm--title');

      // switch todo
      if (this.theme === 'FPASDarkTheme') {
        // todo
      } else if (this.theme === 'FPASLightTheme') {
        // todo
      } else if (this.theme === 'FPASHalloweenEventTheme') {
        sidebar.classList.add('halloweenEventColor');
        for (let i = 0; i < icones.length; i++) {
          icones[i].classList.add('halloweenEventColor');
          headers[i].classList.add('halloweenEventColor');
        }
      }
    },
    notifierErreurLocalStorage() {
      switch (this.theme) {
        case 'FPASLightTheme':
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(5000, 'error', this.$t('errors.lackOfLocalStorage', {}, { locale: this.$i18n.locale }), '');
          break;
        case 'FPASDarkTheme':
        case 'FPASHalloweenEventTheme':
          UtilitiesDarkTheme.CreerAlerteSwalNoQuestion(5000, 'error', this.$t('errors.lackOfLocalStorage', {}, { locale: this.$i18n.locale }), '');
          break;
        default:
          break;
      }
    },
    loadPath() {
      const path = localStorage.getItem('FPASpath');
      if (path !== null && path !== undefined && path !== '') {
        this.$router.push({ path: path});
      }
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.darkTheme {
  background-color: black;
  background-image: linear-gradient(300deg, #000, #333);
}

.lightTheme {
  background-color: white;
  background-image: radial-gradient(#fff, #eeeeee);
}

.goldTheme-GOLD {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #946200 0%, #9f7928 20%, #a37c26 30%, transparent 90%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #ffff7e 8%, #edc455 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.silverTheme-SILVER {
  background: radial-gradient(ellipse farthest-corner at right bottom, #f3f1e7 0%, #aeaaa2 0%, #c8c6c4 -3%, #ffffff 30%, transparent 90%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #fffffe 8%, #f9f8f3 25%, #777369 62.5%, #7c7566 15%)
}

.bronzeTheme-BR0NZ3 {
  background: radial-gradient(ellipse farthest-corner at right bottom, #b97a2d 0%, #ffffff 0%, #835324 -3%, #d87c3a 30%, transparent 90%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffff 3%, #b87b3f 25%, #a75f1b 62.5%, #a95e1e 15%);
}

.augmentationWidth {
  width: 120%;
}

.maxHeight {
  height: 130%;
}
</style>
