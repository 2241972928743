<template>
  <div>
    <Documentation
      :title="'GenerateInvoiceNumberApiV2'"
      :codeExample="'await fpasPayPalServices.GenerateInvoiceNumberApiV2(credentials);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'GenerateInvoiceNumberApiV2View',
  components: {
    Documentation,
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'credentials', type: 'Credentials', description: this.$t('propsDesc.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('generateInvoiceNumberApiV2.description', {}, { locale: this.$i18n.locale });
  },
}
</script>