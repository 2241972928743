<template>
  <header :class="gestionThemes('header')">
    <div style="display:flex">
      <div>
        <button @click="changementHref('/Checkout')" class="buttonGetThemes"><icon style="margin-right:2px" class="fa fa-cart-shopping"></icon>{{ $t("checkout.header", {}, { locale: $i18n.locale }) }}</button>
      </div>
      <div>
        <img
          src="..\..\assets\img\FAANGPlusAPISimplifierLogo.png"
          alt="FAANGPlusApiSimplifier_MainLogo"
          style="width:40%;height:auto"
        >
      </div>
      <div :key="refreshIcon">
        <div v-if="!userConnected" style="padding:15px">
          <a href="/Login" @click="changementHref('/Login')" style="font-size:20px;cursor:pointer" :class="gestionThemes('icon')">
            <i class="fa fa-user"></i>
          </a>
        </div>
        <div v-else style="padding:15px">
          <a href="/Logout" @click="changementHref('/Logout')" style="font-size:20px;cursor:pointer" :class="gestionThemes('icon')">
            <i class="fa-solid fa-right-from-bracket"></i>
          </a>
        </div>
      </div>
    </div>
    <ReusableCode ref="logic" />
  </header>
</template>

<script>
import ReusableCode from '../../components/composantsGlobal/ReusableCode.vue'

export default {
    name: 'HeaderComponent',
    components: {
      ReusableCode
    },
    data() {
      return {
        theme: '',
        estMobile: false,
        userConnected: false,
        refreshIcon: 0, // TODO: CHECKER VOIR SI LE LOGO PEUT CHANGER,
        contentLoaded: false,
      }
    },
    created() {
      this.theme = localStorage.getItem('FPASCurrentTheme');
      const userUID = localStorage.getItem('FPASUserUID');
      if (userUID !== '' && userUID !== undefined) {
        this.userConnected = true;
      }
    },
    mounted() {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          this.estMobile = true;
        }
      this.contentLoaded = true;
    },
    methods: {
      gestionThemes(styleBalise) {
      if (this.contentLoaded)
        return this.$refs.logic.gestionThemes(styleBalise);
      },
      changementHref(composant) {
        const item = {
          href: composant
        }
        this.$emit('changementHrefHeader', item);
      },
    },
}
</script>

<style scoped>
</style>