/* eslint-disable no-unused-vars */
import swal from 'sweetalert2';
let estMobile = false;

const utilities = {
  CreerAlerteSwalNoQuestion(tempsMillis, icone, titre, description) {
    swal.fire({
      title: titre,
      text: description,
      icon: icone,
      timer: tempsMillis,
      position: 'top-start',
      toast: true,
      showConfirmButton: false,
      timerProgressBar: true,
      width: localStorage.getItem('FPASSwalLength')
    });
  },
  async importerDarkTheme() {
    const theme = localStorage.getItem('FPASCurrentTheme');
    switch (theme) {
      case 'FPASDarkTheme':
      case 'FPASHalloweenEventTheme':
        await import('@sweetalert2/theme-dark/dark.css')
        break;
      default:
        break;
    }
  },
  async verificationMobile() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      estMobile = true;
    }

    // Modification du width
    if (estMobile === false) {
      localStorage.setItem('FPASSwalLength', '500px');
    } else {
      localStorage.setItem('FPASSwalLength', '320px');
    }
  }
}

window.onload = async function() {
  await utilities.importerDarkTheme();
  await utilities.verificationMobile();
};

export default utilities;