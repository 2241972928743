<template>
  <div :class="validationEspacement()">
    <div
      id="checkoutContent"
      :class="gestionThemes('border')"
      style="margin-top:30px;padding-top:10px"
    >
      <div>
        <p :class="gestionThemes('texte')" style="font-size:30px" class="checkoutHeader">
          <b>{{ $t("checkout.header", {}, { locale: $i18n.locale }) }}</b>
        </p><br>
      </div>
      <div v-if="themesAvailableToPurchase">
        <div :style="!estMobile ? 'padding:20px' : ''">
          <div id="themeOptions">
            <div>
              <!-- Subscriptions Dark Theme + complete bundle -->
              <p style="margin-top:0" :class="gestionThemes('texte')">{{ $t("checkout.hugeThanks", {}, { locale: $i18n.locale }) }}</p>
              <p style="margin-top:0" :class="gestionThemes('texte')"><u>{{ $t("checkout.subscriptionOptions", {}, { locale: $i18n.locale }) }}</u></p>
              <div style="padding:10px">
                <!-- Precious metals -->
                <div style="width: 100%;margin-bottom:10px" class="subscriptionBoxSize" :class="gestionThemes('borderAndText')">
                  <p style="padding:5px" :class="gestionThemes('texte')"><b>{{ $t("checkout.preciousMetalsBundle.header", {}, { locale: $i18n.locale }) }}</b> - <i>{{ $t("checkout.preciousMetalsBundle.subscriptionPrice", {}, { locale: $i18n.locale }) }}</i></p>
                  <p :class="gestionThemes('texte')"><u>{{ $t("checkout.unlockThemes", {}, { locale: $i18n.locale }) }}</u></p>
                  <p class="unlockedThemes"><b>{{ preciousMetalsThemesText }}</b></p>
                  <div class="ajustementBoutonsPayPal" id="paypalButtonsPreciousMetalsSubscription"></div>
                </div>
              </div>

              <div style="padding:10px" :style="!estMobile ? 'display:flex' : ''">
                <!-- Dark theme -->
                <div :style="!estMobile ? 'width:50%' : 'width:100%'" class="subscriptionBoxSize" :class="gestionThemes('borderAndText')">
                  <p style="padding:5px" :class="gestionThemes('texte')"><b>{{ $t("checkout.darkThemeOnly.header", {}, { locale: $i18n.locale }) }}</b> - <i>{{ $t("checkout.darkThemeOnly.subscriptionPrice", {}, { locale: $i18n.locale }) }}</i></p>
                  <p :class="gestionThemes('texte')"><u>{{ $t("checkout.unlockThemes", {}, { locale: $i18n.locale }) }}</u></p>
                  <p class="unlockedThemes"><b>{{ $t("sidebar.themes.dark", {}, { locale: $i18n.locale }) }}</b></p>
                  <div class="ajustementBoutonsPayPal" id="paypalButtonsDarkThemeSubscription"></div>
                </div>
                <!-- Holidays bundle -->
                <div :style="!estMobile ? 'width:50%;margin-left:10px' : 'width:100%;margin-top:10px'" class="subscriptionBoxSize" :class="gestionThemes('borderAndText')">
                  <p style="padding:5px" :class="gestionThemes('texte')"><b>{{ $t("checkout.holidaysBundle.header", {}, { locale: $i18n.locale }) }}</b> - <i>{{ $t("checkout.holidaysBundle.subscriptionPrice", {}, { locale: $i18n.locale }) }}</i></p>
                  <p :class="gestionThemes('texte')"><u>{{ $t("checkout.unlockThemes", {}, { locale: $i18n.locale }) }}</u></p>
                  <p class="unlockedThemes"><b>{{ holidaysBundleThemesText }}</b></p>
                  <div class="ajustementBoutonsPayPal" id="paypalButtonsHolidaysBundleSubscription"></div>
                  <p style="margin-top:0" class="someExceptions" :class="gestionThemes('texte')"><u><i>{{ $t("checkout.holidaysBundle.moreInfo", {}, { locale: $i18n.locale }) }}</i></u></p>
                  <!-- <p class="someExceptions" :class="gestionThemes('texte')"><b><i>{{ $t("checkout.exceptions.holidaysBundleException", {}, { locale: $i18n.locale }) }}</i></b></p> -->
                </div>
              </div>

              <div style="padding:10px">
                <p style="font-size:30px" :class="gestionThemes('texte')">{{ $t("checkout.themesDemo", {}, { locale: $i18n.locale }) }}</p>
                <Carousel
                  :imagesInformation="images"
                  :hideLeftRightButtons="false"
                  :automaticSlideShowActivated="false"
                />
              </div>

              <!-- Exceptions -->
              <!-- <div style="margin-top:40px" :class="gestionThemes('texte')">
                <p style="font-size:30px"><u><b><i>{{ $t("checkout.exceptions.header", {}, { locale: $i18n.locale }) }}</i></b></u></p>
                <p style="font-size:14px"><i>{{ $t("checkout.exceptions.holidaysThemesLimitation", {}, { locale: $i18n.locale }) }}</i></p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="padding-bottom:20px"
      >
        <p
          class="borderAndText comingSoon"
          :class="gestionThemes('borderAndText')"
        >
          {{ $t("checkout.comingSoon", {}, { locale: $i18n.locale }) }}
        </p>
      </div>
    </div>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import Carousel from '../../components/composantsGlobal/Carousel.vue';
import ReusableCode from '../../components/composantsGlobal/ReusableCode.vue';
import { loadScript } from '@paypal/paypal-js';

export default {
  name: 'CheckoutView',
  components: {
    ReusableCode,
    Carousel
  },
  data() {
    return {
      estMobile: false,
      contentLoaded: false,
      customAmount: 0,
      total: 0,
      refreshTotal: 0,
      themesAvailableToPurchase: false, // Todo: Mettre à true une fois available
      holidaysBundleThemes: [],
      holidaysBundleThemesText: '',
      preciousMetalsThemes: [],
      preciousMetalsThemesText: '',
    }
  },
  computed: {
    images() {
      const imagesArray = [
        { image: 'FPASDarkThemeDemo.png', name: this.$t('sidebar.themes.dark', {}, { locale: this.$i18n.locale }) },
        { image: 'FPASGleamingGoldThemeDemo.png', name: this.$t('sidebar.themes.preciousMetalsThemes.goldTheme', {}, { locale: this.$i18n.locale }) },
        { image: 'FPASShiningSilverThemeDemo.png', name: this.$t('sidebar.themes.preciousMetalsThemes.silverTheme', {}, { locale: this.$i18n.locale }) },
        { image: 'FPASBrightBronzeThemeDemo.png', name: this.$t('sidebar.themes.preciousMetalsThemes.bronzeTheme', {}, { locale: this.$i18n.locale }) }
      ];
      return imagesArray;
    },
  },
  created() {
    this.theme = localStorage.getItem('FPASCurrentTheme');

    // Insertion des thèmes
    this.setupPreciousMetalsBundleText();

    // Insertion des thèmes du holidays + dark theme bundle
    this.setupHolidaysAndDarkThemeBundleText();
  },
  async mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
    this.contentLoaded = true;
    this.$nextTick(() => {
      this.contentStyle();
    })

    // TODO: CHANGER CLIENT ID
    // TODO: 2$ = dark theme, 15$ = tout (incluant futurs)
    let paypalSdk = '';
    let darkThemeOnlySubscriptionPlanId = '';
    let holidaysBundleSubscriptionPlanId = '';
    let preciousMetalsBundleSubscriptionPlanId = '';
    const environment = process.env.NODE_ENV;

    if (environment === 'development') {
      // ENVIRONNEMENT DEV
      paypalSdk = await loadScript({
        'client-id': 'AR19Jcj6t7O_Ukx31xreYadhJaoeZ8PAgXVCHm95NVOPJWcA0KIY0jvviyirrY4cWazmK4e8PjkI6dfu',
        currency: 'CAD',
        vault: true
      });
      darkThemeOnlySubscriptionPlanId = 'P-6LJ96409VS7126133MSPBATA';
      holidaysBundleSubscriptionPlanId = 'P-5T329164XR8633816MSEQW3Y';
      preciousMetalsBundleSubscriptionPlanId = 'P-5L9309464H7011545MTDSTGI';
    } else {
      // TODO: AFFECTER LES PLAN ID DE PROD
    }

    // DARK THEME SUBSCRIPTION BUTTONS
    paypalSdk.Buttons({
      style: {
        layout: 'vertical',
        color:  'white',
        shape:  'rect',
        label:  'paypal',
        disableMaxWidth: true
      },
      createSubscription(data, actions) {
        return actions.subscription.create({
          'plan_id': darkThemeOnlySubscriptionPlanId
        });
      },
      onApprove() {
        console.log('test')
      }
    }).render('#paypalButtonsDarkThemeSubscription');

    // HOLIDAYS SUBSCRIPTION BUNDLE + DARK THEME
    paypalSdk.Buttons({
      style: {
        layout: 'vertical',
        color:  'blue',
        shape:  'rect',
        label:  'paypal',
        disableMaxWidth: true
      },
      createSubscription(data, actions) {
        return actions.subscription.create({
          'plan_id': holidaysBundleSubscriptionPlanId
        });
      },
      onApprove() {
        console.log('test')
      }
    }).render('#paypalButtonsHolidaysBundleSubscription');

    // PRECIOUS METALS BUNDLE
    paypalSdk.Buttons({
      style: {
        layout: 'vertical',
        color:  'gold',
        shape:  'rect',
        label:  'paypal',
        disableMaxWidth: true
      },
      createSubscription(data, actions) {
        return actions.subscription.create({
          'plan_id': preciousMetalsBundleSubscriptionPlanId
        });
      },
      onApprove() {
        console.log('test')
      }
    }).render('#paypalButtonsPreciousMetalsSubscription');

    this.$refs.logic.setMaxHeight('checkout');
  },
  methods: {
    validationEspacement() {
      if (this.contentLoaded) {
        if (!this.estMobile)
          return 'espacementDesktop';
        return '';
      }
    },
    gestionThemes(typeBalise) {
      if (this.contentLoaded)
        return this.$refs.logic.gestionThemes(typeBalise);
    },
    contentStyle() {
      if (this.contentLoaded) {
        var content = document.getElementById('checkoutContent');
        if (this.estMobile)
          content.classList.add('affichageContenuMobile');
        else
          content.classList.add('affichageContenu');
      }
    },
    setupPreciousMetalsBundleText() {
      this.preciousMetalsThemes.push({ themeName: this.$t('sidebar.themes.preciousMetalsThemes.bronzeTheme', {}, { locale: this.$i18n.locale })});
      this.preciousMetalsThemes.push({ themeName: this.$t('sidebar.themes.preciousMetalsThemes.silverTheme', {}, { locale: this.$i18n.locale })});
      this.preciousMetalsThemes.push({ themeName: this.$t('sidebar.themes.preciousMetalsThemes.goldTheme', {}, { locale: this.$i18n.locale })});
      for (let i = 0; i < this.preciousMetalsThemes.length; i++) {
        const theme = this.preciousMetalsThemes[i];
        this.preciousMetalsThemesText += theme.themeName;
        if ((i + 1) !== this.preciousMetalsThemes.length) {
          this.preciousMetalsThemesText += ', ';
        }
      }
    },
    setupHolidaysAndDarkThemeBundleText() {
      this.holidaysBundleThemes.push({ themeName: this.$t('sidebar.themes.dark', {}, { locale: this.$i18n.locale })});
      this.holidaysBundleThemes.push({ themeName: this.$t('sidebar.themes.halloween', {}, { locale: this.$i18n.locale })});
      this.holidaysBundleThemes.push({ themeName: this.$t('sidebar.themes.christmas', {}, { locale: this.$i18n.locale })});
      for (let i = 0; i < this.holidaysBundleThemes.length; i++) {
        const theme = this.holidaysBundleThemes[i];
        this.holidaysBundleThemesText += theme.themeName;
        if ((i + 1) !== this.holidaysBundleThemes.length) {
          this.holidaysBundleThemesText += ', ';
        }
      }
    }
  },
}
</script>

<style scoped>
.ajustementBoutonsPayPal {
  padding-top: 20px;
  margin:auto;
  padding-left: 22px;
  padding-right: 22px;
}

table {
  border: 2px solid black;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.checkoutHeader {
  padding: 0;
  margin-top: 10px;
  margin-bottom: -15px;
}

.captionStyles {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px solid black;
}

.contributorsUsername {
  margin: 0;
  padding: 0;
  line-break: anywhere;
}

.comingSoon {
  padding: 20px;
  margin: auto;
  width: 50%;
  border-radius: 10px;
  margin-top: 20px;
}

.subscriptionBoxSize {
  width: 50%;
  border-radius: 30px;
}

.someExceptions {
  padding:5px;
  font-size:12px;
  margin-top: -15px;
}
</style>