import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueSweetalert2 from 'vue-sweetalert2';
import { createI18n } from 'vue-i18n';
import VueStripeMenu from 'vue-stripe-menu'

// Importation de firebase + les environnements (initialisation faite toute en bas)
//#region Firebase
import { initializeApp } from 'firebase/app';
const firebaseConfigPROD = {
    apiKey: 'AIzaSyBQr1B8tpyRK-DvxMBkJ4zirAiIm-NsgxI',
    authDomain: 'faangplusapisimplifier-prod.firebaseapp.com',
    databaseURL: 'https://faangplusapisimplifier-prod-default-rtdb.firebaseio.com',
    projectId: 'faangplusapisimplifier-prod',
    storageBucket: 'faangplusapisimplifier-prod.appspot.com',
    messagingSenderId: '1034726952073',
    appId: '1:1034726952073:web:84fdd7af9f18bbc9acca58',
    measurementId: 'G-P865079815'
};

const firebaseDEVConfig = {
  apiKey: 'AIzaSyDZoM7UBzBAtkVZID7y9aXJQZJYRkZa-Uo',
  authDomain: 'faangplusapisimplifier-dev.firebaseapp.com',
  projectId: 'faangplusapisimplifier-dev',
  storageBucket: 'faangplusapisimplifier-dev.appspot.com',
  messagingSenderId: '777730489963',
  appId: '1:777730489963:web:8a805533d6c46737ff7b2e',
  measurementId: 'G-GLX2GJ2TV6'
};

const environment = process.env.NODE_ENV;
if (environment === 'development') {
    initializeApp(firebaseDEVConfig);
} else {
    initializeApp(firebaseConfigPROD);
}
//#endregion

const i18n = createI18n({
    legacy: false,
    locale: 'en-US',
    messages: {
        'en-US': {
            lackOfLocalStorage: 'Internationalization might not work due to the lack of a local storage on your current browser',
            errors: {
                videoTagError: 'Sorry, your browser does not support the video tag.',
                lackOfLocalStorage: 'Internationalization might not work due to the lack of a local storage on your current browser',
                styleError: 'Styling error',
                styleErrorDesc: 'Please contact me to report the error',
                signup: {
                    usernameRequired: 'The username is required',
                    emailRequired: 'The email is required',
                    invalidEmailFormat: 'Email format is invalid',
                    passwordRequired: 'The password is required and must contain at least 8 characters',
                    passwordlengthInvalid: 'The password must contain at least 8 characters',
                    confirmationRequired: 'The password must be confirmed',
                    confirmationMismatch: 'The password and confirmation do not match'
                },
                login: {
                    passwordRequired: 'The password is required',
                    invalidInput: 'One of the fields is invalid. Make sure you have entered your information correctly.'
                }
            },
            sidebar: {
                home: 'Home',
                options: 'Options',
                language: 'Language',
                theme: 'Theme',
                themes: {
                    dark: 'Dark',
                    light: 'Light',
                    halloween: 'Halloween',
                    christmas: 'Christmas',
                    preciousMetalsThemes: {
                        goldTheme: 'Gleaming Gold',
                        goldPinkTheme: 'Lustrous Pink Gold',
                        preciousMetalsHeader: 'Precious Metals',
                        silverTheme: 'Shining Silver',
                        bronzeTheme: 'Bright Bronze'
                    }
                },
                new: 'New',
                music: {
                    header: 'Music',
                    on: 'On',
                    off: 'Off'
                },
                contact: {
                    header: 'Contact',
                    linkedIn: 'LinkedIn',
                    github: 'GitHub',
                    mail: 'Email'
                },
                paypalServices: {
                    invoices: 'Invoices',
                    transactions: 'Transactions'
                },
                googleServices: {
                    youtube: 'YouTube'
                },
                miscServices: {
                    countApi: 'CountAPI'
                },
                note: {
                    copyright: 'Anthony Boileau © 2023'
                },
                mainMenu: 'Main menu'
            },
            mobileMenu: {
                availableMethods: 'Available functions'
            },
            halloweenEvent: {
                BGM1: 'Music: Halloween Theme 1 by Alexander Nakarada - https://www.serpentsoundstudios.com',
                BGM1Info: 'Music promoted by https://www.free-stock-music.com - Creative Commons / Attribution 4.0 International (CC BY 4.0) - https://creativecommons.org/licenses/by/4.0/',
                BGM2: 'Music: Maleficus by Scott Buckley - https://soundcloud.com/scottbuckley',
                BGM2Info: 'Music promoted by https://www.free-stock-music.com - Creative Commons / Attribution 4.0 International (CC BY 4.0) - https://creativecommons.org/licenses/by/4.0/',
                BGM3: 'Music: Don\'t Go Inside by WombatNoisesAudio - https://soundcloud.com/user-734462061',
                BGM3Info: 'Music promoted by https://www.free-stock-music.com - Creative Commons / Attribution 3.0 Unported License (CC BY 3.0) - https://creativecommons.org/licenses/by/3.0/deed.en_US',
            },
            christmasEvent: {
                BGM1: 'Music: Christmas Is Coming by MaxKoMusic | https://maxkomusic.com/',
                BGM1Info: 'Music promoted by https://www.free-stock-music.com - Creative Commons / Attribution-ShareAlike 3.0 Unported (CC BY-SA 3.0) - https://creativecommons.org/licenses/by-sa/3.0/deed.en_US',
                BGM2: 'Music: Snowfall by Punch Deck | https://soundcloud.com/punch-deck',
                BGM2Info: 'Music promoted by https://www.free-stock-music.com - Creative Commons / Attribution 3.0 Unported License (CC BY 3.0) - https://creativecommons.org/licenses/by/3.0/deed.en_US',
                BGM3: 'Music: First Snow by Scott Buckley | https://soundcloud.com/scottbuckley',
                BGM3Info: 'Music promoted by https://www.free-stock-music.com - Creative Commons / Attribution 4.0 International (CC BY 4.0) - https://creativecommons.org/licenses/by/4.0/',
            },
            eventPopup: {
                question: 'Do you want to start the music for this event?',
                oui: 'Yes',
                non: 'No'
            },
            home: {
                welcome: 'WELCOME',
                packageDescription: 'Simplifier of several APIs, including those of Facebook, Amazon, Apple, Netflix (unofficial), Google and more',
                links: 'Access the package on Nuget.org to access the following features:',
                visitFor: 'Visit the site for:',
                feature1: 'Full package documentation',
                feature2: 'A roadmap of upcoming features',
                feature3: 'Additional information',
                sidebarExplanation: 'Use the left sidebar to access documentation, contact me, or change supported themes and languages.',
                specialThemes: 'Special themes will also be available with a subscription.',
                fpasAbbreviation: 'FPAS (FAANGPlusApiSimplifier) is the short name of this package.',
                disclaimer: 'Please note that adding new features may vary depending on my work pace and availability.'
            },
            propsDesc: {
                credentialsDesc: 'Class that contains the client id, secret and environment for PayPal API calls',
                invoiceV2Desc: 'Class that contains the information needed to create a PayPal invoice with the V2 API',
                sendEmailToInvoicerDesc: 'Indicates whether to send a copy of the email to the merchant',
                paypalInvoiceIdDesc: 'PayPal invoice ID (e.g. INV2-Z56S-5LLA-Q52L-CPZ5)',
                paypalCancellationObjectDesc: 'Class that contains the information needed to cancel a PayPal invoice with the V2 API',
                paypalTransactionIdDesc: 'PayPal Transaction ID (e.g. 8FS54075XP938102R)',
                paypalRefundV2Desc: 'Class that contains the information needed to refund a PayPal invoice with the V2 API',
                paypalRefundsEnumDesc: 'Enumeration that specifies whether the refund is partial or not',
                paypalInvoiceQRDesc: 'Class that contains the properties to generate a QR code with the V2 API',
                paypalQueryParamDesc: 'List of available query parameters: https://developer.paypal.com/docs/api/transaction-search/v1/#transactions_get. Example: start_date=2023-03-01T00:17:44-0700&end_date=2023-03-31T00:17:44-0700',
                youtube: {
                    credentialsDesc: 'Class that contains the necessary informations for YouTube API calls',
                    searchApiV3Desc: 'Class that contains the information needed to search videos with the V3 API',
                    partAndHlApiV3Desc: 'Class that contains the information for the part and hl parameters',
                    videoIdDesc: 'YouTube video ID (string after v= in a YouTube video URL)',
                    captionTrackIdDesc: 'YouTube video caption track id (use GetVideoCaptionsTracksApiV3 to get them)',
                    downloadCaptionsV3Desc: 'Class that contains the required parameters for the DownloadCaptionsV3 method',
                    getSubscriptionsV3Desc: 'Class that contains the required parameters for the GetSubscriptionsApiV3 method'
                },
                countApi: {
                    namespaceParameterDesc: 'Each counter is identified inside a namespace with a key. The namespace should be unique, so its recommended using your site\'s domain',
                    keyDesc: 'The key specified with the namespace'
                },
                netflix: {
                    credentialsDesc: 'Class that contains the required parameters for uNoGS (Unofficial Netflix Online Global Search) API calls',
                    searchForPeopleApiV2Desc: 'Class that contains the necessary parameters for the SearchForPeopleApiV2 method',
                    fPASNetflixSearchForTitlesApiV2: 'Class that contains the necessary parameters for the FPASNetflixSearchForTitlesApiV2 method'
                }
            },
            createAndSendInvoiceApiV2: {
                description: 'Method that allows to create and send a PayPal invoice using the API V2'
            },
            cancelSentInvoiceApiV2: {
                description: 'Method that allows to cancel a PayPal invoice using the API V2'
            },
            createInvoiceApiV2: {
                description: 'Method that allows to create a draft PayPal invoice using the API V2. To move the invoice from a draft to payable state, you must send the invoice.'
            },
            sendInvoiceApiV2: {
                description: 'Method that allows to move the invoice from a draft to payable state.',
            },
            deleteInvoiceApiV2: {
                description: 'Method that allows to delete a PayPal invoice using the API V2. Note: Only invoices in draft, scheduled, or canceled status can be deleted.'
            },
            generateInvoiceNumberApiV2: {
                description: 'Method that allows to generate an invoice number using the API V2'
            },
            refundInvoiceApiV2: {
                description: 'Method that allows to refund a PayPal invoice using the API V2'
            },
            getInvoiceDetailsApiV2: {
                description: 'Method that allows to get an invoice details using the API V2'
            },
            generateInvoiceQRCodeApiV2: {
                description: 'Method that allows to generate a QR code for an invoice using the API V2'
            },
            listTransactionsApiV1: {
                description: 'Method that allows to list PayPal transactions using the API V1'
            },
            searchVideosApiV3: {
                description: 'Method that allows to search YouTube videos using the API V3'
            },
            getVideoReportReasonsApiV3: {
                description: 'Method that allows to get YouTube\'s video abuse report reasons using the API V3'
            },
            getI18nLanguagesApiV3: {
                description: 'Method that allows to get YouTube\'s i18n languages using the API V3'
            },
            documentation: {
                codeExample: 'Code exemple:',
                parameters: 'Parameters',
                syntax: 'Syntax',
                deprecationWarning: 'DEPRECATED, this feature is no longer maintained by the original developers, so I can\'t fix it anymore.'
            },
            componentsDesc: {
                getVideoCaptionsTracksApiV3: 'Returns a list of caption tracks that are associated with a specified video. This API response does not contain the actual captions (use DownloadVideoCaptionsApiV3 instead)',
                downloadVideoCaptionsApiV3: 'Returns a list of caption associated with a specified video. Note: This only works for your own videos or videos with third-party captions contributions enabled',
                getSubscriptionsApiV3: 'Returns a list of subscriptions based on the provided parameters',
                getCountApiValue: 'Method that allows to create simple numeric counters using CountAPI',
                searchForPeopleApiV2: 'Method that allows to search for individuals based on their title name, person name, or unique Netflix_ID using the uNoGS (Unofficial Netflix Online Global Search) API V2.',
                searchForTitlesApiV2: 'Method that allows to obtain an extensive range of title search options using the uNoGS (Unofficial Netflix Online Global Search) API V2',
                getListOfGenresApiV2: 'Method that allows to retrieve an extensive list of genres using the uNoGS (Unofficial Netflix Online Global Search) API V2.',
                getListOfSupportedCountriesApiV2: 'Method that allows to to obtain an up-to-date list of supported countries using the uNoGS (Unofficial Netflix Online Global Search) API V2'
            },
            signUp: {
                header: 'Sign up',
                createAccount: 'CREATE AN ACCOUNT',
                username: 'Username:',
                email: 'Email:',
                password: 'Password:',
                confirmPassword: 'Confirm password:',
                create: 'Create',
                accountCreatedSuccessfully: 'Account created successfully!'
            },
            login: {
                header: 'Login',
                email: 'Email:',
                password: 'Password:',
                btnLogin: 'Login',
                loginSuccessful: 'Login successful',
                signUp: 'You do not have an account? Create one!'
            },
            checkout: {
                header: 'Unlock themes',
                darkThemeOnly: {
                    header: 'Dark theme only',
                    subscriptionPrice: '1,50$CA/month',
                },
                holidaysBundle: {
                    header: 'Holidays bundle + Dark theme',
                    moreInfo: 'Also contains all future holiday styles (e.g. Saint Patrick)',
                    subscriptionPrice: '10,00$CA/month',
                },
                preciousMetalsBundle: {
                    header: 'Precious metals',
                    subscriptionPrice: '7,50$CA/month',
                },
                subscriptionOptions: 'Subscription options:',
                exceptions: {
                    header: 'Exceptions:',
                    holidaysBundleException: '*Some exceptions apply',
                    holidaysThemesLimitation: '* Themes from the "Precious Metals" subscription are not included in this subscription.'
                },
                hugeThanks: 'I would like to thank everyone who supports the project!',
                comingSoon: 'Coming soon!',
                unlockThemes: 'Unlock the following themes:',
                themesDemo: 'Demonstration:'
            }
        },
        'fr': {
            lackOfLocalStorage: "L'internationalisation peut ne pas fonctionner en raison de l'absence de stockage local sur votre navigateur actuel",
            errors: {
                videoTagError: 'Désolé, votre navigateur ne prend pas en charge la balise vidéo.',
                lackOfLocalStorage: "L'internationalisation peut ne pas fonctionner en raison de l'absence de stockage local sur votre navigateur actuel",
                styleError: 'Erreur de style',
                styleErrorDesc: 'Veuillez me contacter pour reporter l\'erreur',
                signup: {
                    usernameRequired: 'Le nom d\'utilisateur est nécessaire',
                    emailRequired: 'Le courriel est nécessaire',
                    invalidEmailFormat: 'Le format du courriel est invalide',
                    passwordRequired: 'Le mot de passe est nécessaire et doit contenir au moins 8 caractères',
                    passwordlengthInvalid: 'Le mot de passe doit contenir au moins 8 caractères',
                    confirmationRequired: 'Le mot de passe doit être confirmé',
                    confirmationMismatch: 'Le mot de passe et la confirmation ne correspondent pas'
                },
                login: {
                    passwordRequired: 'Le mot de passe est nécessaire',
                    invalidInput: 'Un des champs est invalide. Assurez-vous d\'avoir bien saisi vos informations.'
                }
            },
            sidebar: {
                home: 'Accueil',
                options: 'Options',
                language: 'Langue',
                theme: 'Thème',
                themes: {
                    dark: 'Sombre',
                    light: 'Clair',
                    halloween: 'Halloween',
                    christmas: 'Noël',
                    preciousMetalsThemes: {
                        goldTheme: 'Or Brillant',
                        goldPinkTheme: 'Or Rose Brillant',
                        preciousMetalsHeader: 'Métaux Précieux',
                        silverTheme: 'Argent Brillant',
                        bronzeTheme: 'Bronze Brillant'
                    }
                },
                new: 'Nouveau',
                music: {
                    header: 'Musique',
                    on: 'Démarrer',
                    off: 'Arrêter'
                },
                contact: {
                    header: 'Contact',
                    linkedIn: 'LinkedIn',
                    github: 'GitHub',
                    mail: 'Courriel'
                },
                paypalServices: {
                    invoices: 'Factures',
                    transactions: 'Transactions'
                },
                googleServices: {
                    youtube: 'YouTube'
                },
                miscServices: {
                    countApi: 'CountAPI'
                },
                note: {
                    copyright: 'Anthony Boileau © 2023'
                },
                mainMenu: 'Menu principal'
            },
            mobileMenu: {
                availableMethods: 'Méthodes disponibles'
            },
            halloweenEvent: {
                BGM1: 'Musique: Halloween Theme 1 by Alexander Nakarada - https://www.serpentsoundstudios.com',
                BGM1Info: 'Musique promue par https://www.free-stock-music.com - Creative Commons / Attribution 4.0 International (CC BY 4.0) - https://creativecommons.org/licenses/by/4.0/',
                BGM2: 'Musique: Maleficus by Scott Buckley - https://soundcloud.com/scottbuckley',
                BGM2Info: 'Musique promue par https://www.free-stock-music.com - Creative Commons / Attribution 4.0 International (CC BY 4.0) - https://creativecommons.org/licenses/by/4.0/',
                BGM3: 'Musique: Don\'t Go Inside by WombatNoisesAudio - https://soundcloud.com/user-734462061',
                BGM3Info: 'Musique promue par https://www.free-stock-music.com - Creative Commons / Attribution 3.0 Unported License (CC BY 3.0) - https://creativecommons.org/licenses/by/3.0/deed.en_US',
            },
            christmasEvent: {
                BGM1: 'Musique: Christmas Is Coming by MaxKoMusic - https://maxkomusic.com/',
                BGM1Info: 'Musique promue par https://www.free-stock-music.com - Creative Commons / Attribution-ShareAlike 3.0 Unported (CC BY-SA 3.0) - https://creativecommons.org/licenses/by-sa/3.0/deed.en_US',
                BGM2: 'Musique: Snowfall by Punch Deck - https://soundcloud.com/punch-deck',
                BGM2Info: 'Musique promue par https://www.free-stock-music.com - Creative Commons / Attribution 3.0 Unported License (CC BY 3.0) - https://creativecommons.org/licenses/by/3.0/deed.en_US',
                BGM3: 'Musique: First Snow by Scott Buckley - https://soundcloud.com/scottbuckley',
                BGM3Info: 'Musique promue par https://www.free-stock-music.com - Creative Commons / Attribution 4.0 International (CC BY 4.0) - https://creativecommons.org/licenses/by/4.0/',
            },
            eventPopup: {
                question: 'Voulez-vous démarrer la musique de cet événement?',
                oui: 'Oui',
                non: 'Non'
            },
            home: {
                welcome: 'BIENVENUE',
                packageDescription: 'Simplificateur de plusieurs API, dont celles de Facebook, Amazon, Apple, Netflix (non officiel), Google et plus',
                links: 'Accédez au package sur Nuget.org pour profiter des fonctionnalités suivantes :',
                visitFor: 'Visitez le site pour:',
                feature1: 'La documentation complète du package',
                feature2: 'Une feuille de route des prochaines fonctionnalités à venir',
                feature3: 'Des informations supplémentaires',
                sidebarExplanation: 'Utilisez la barre latérale gauche pour consulter la documentation, me contacter ou modifier les thèmes et les langages pris en charge.',
                specialThemes: 'Des thèmes spéciaux seront également disponibles avec un abonnement.',
                fpasAbbreviation: 'FPAS (FAANGPlusApiSimplifier) est le nom abrégé de ce package.',
                disclaimer: 'Veuillez noter que l\'ajout de nouvelles fonctionnalités peut varier en fonction de mon rythme de travail et de mes disponibilités.'
            },
            propsDesc: {
                credentialsDesc: 'Classe contenant l\'identifiant client, le secret et l\'environnement pour les appels de l\'API PayPal',
                invoiceV2Desc: 'Classe qui contient les informations nécessaires pour créer une facture PayPal avec l\'API V2',
                sendEmailToInvoicerDesc: 'Indique s\'il faut envoyer une copie du courriel au marchand',
                paypalInvoiceIdDesc: 'Identifiant de facture PayPal (ex: INV2-Z56S-5LLA-Q52L-CPZ5)',
                paypalCancellationObjectDesc: 'Classe qui contient les informations nécessaires pour annuler une facture PayPal avec l\'API V2',
                paypalTransactionIdDesc: 'Identifiant de transaction PayPal (ex: 8FS54075XP938102R)',
                paypalRefundV2Desc: 'Classe qui contient les informations nécessaires pour rembourser une facture PayPal avec l\'API V2',
                paypalRefundsEnumDesc: 'Énumération qui précise si le remboursement est partiel ou non',
                paypalInvoiceQRDesc: 'Classe qui contient les propriétés pour générer un code QR avec l\'API V2',
                paypalQueryParamDesc: 'Liste des paramètres de requête disponibles : https://developer.paypal.com/docs/api/transaction-search/v1/#transactions_get. Exemple: start_date=2023-03-01T00:17:44-0700&end_date=2023-03-31T00:17:44-0700',
                youtube: {
                    credentialsDesc: 'Classe contenant les informations nécessaires aux appels de l\'API YouTube',
                    searchApiV3Desc: 'Classe qui contient les informations nécessaires pour rechercher des vidéos avec l\'API V3',
                    partAndHlApiV3Desc: 'Classe qui contient les informations pour les paramètres part et hl',
                    videoIdDesc: 'ID de vidéo YouTube (texte après v= dans une URL de vidéo YouTube)',
                    captionTrackIdDesc: 'ID de piste de sous-titres de vidéo YouTube (utilisez GetVideoCaptionsTracksApiV3 pour les obtenir)',
                    downloadCaptionsV3Desc: 'Classe contenant les paramètres nécessaires pour la méthode DownloadCaptionsV3',
                    getSubscriptionsV3Desc: 'Classe contenant les paramètres nécessaires pour la méthode GetSubscriptionsApiV3'
                },
                countApi: {
                    namespaceParameterDesc: 'Chaque compteur est identifié à l\'intérieur d\'un espace de noms par une clé. L\'espace de noms doit être unique, il est donc recommandé d\'utiliser le domaine de votre site',
                    keyDesc: 'La clé spécifiée avec l\'espace de noms'
                },
                netflix: {
                    credentialsDesc: 'Classe contenant les paramètres requis pour les appels d\'API uNoGS (Unofficial Netflix Online Global Search)',
                    searchForPeopleApiV2Desc: 'Classe contenant les paramètres nécessaires pour la méthode SearchForPeopleApiV2',
                    fPASNetflixSearchForTitlesApiV2: 'Classe qui contient les paramètres nécessaires pour la méthode FPASNetflixSearchForTitlesApiV2'
                }
            },
            createAndSendInvoiceApiV2: {
                description: 'Méthode qui permet de créer et d\'envoyer une facture PayPal en utilisant l\'API V2'
            },
            cancelSentInvoiceApiV2: {
                description: 'Méthode permettant d\'annuler une facture PayPal à l\'aide de l\'API V2'
            },
            createInvoiceApiV2: {
                description: 'Méthode qui permet de créer un brouillon de facture PayPal à l\'aide de l\'API V2. Pour faire passer la facture de l\'état brouillon à l\'état payable, vous devez envoyer la facture.'
            },
            sendInvoiceApiV2: {
                description: 'Méthode qui permet de faire passer la facture d\'un état brouillon à payable.',
            },
            deleteInvoiceApiV2: {
                description: 'Méthode permettant de supprimer une facture PayPal à l\'aide de l\'API V2. Remarque : Seules les factures au statut brouillon, planifié ou annulé peuvent être supprimées.'
            },
            generateInvoiceNumberApiV2: {
                description: 'Méthode permettant de générer un numéro de facture à l\'aide de l\'API V2'
            },
            refundInvoiceApiV2: {
                description: 'Méthode qui permet de rembourser une facture PayPal en utilisant l\'API V2'
            },
            getInvoiceDetailsApiV2: {
                description: 'Méthode permettant d\'obtenir les détails d\'une facture à l\'aide de l\'API V2'
            },
            generateInvoiceQRCodeApiV2: {
                description: 'Méthode permettant de générer un QR code pour une facture à l\'aide de l\'API V2'
            },
            listTransactionsApiV1: {
                description: 'Méthode permettant de lister les transactions PayPal à l\'aide de l\'API V1'
            },
            searchVideosApiV3: {
                description: 'Méthode qui permet de rechercher des vidéos YouTube à l\'aide de l\'API V3'
            },
            getVideoReportReasonsApiV3: {
                description: 'Méthode qui permet d\'obtenir les raisons du rapport d\'abus vidéo de YouTube à l\'aide de l\'API V3'
            },
            getI18nLanguagesApiV3: {
                description: 'Méthode qui permet d\'obtenir les langues i18n de YouTube en utilisant l\'API V3'
            },
            documentation: {
                codeExample: 'Exemple de code:',
                parameters: 'Paramètres',
                syntax: 'Syntaxe',
                deprecationWarning: 'INSUPPORTÉE, cette fonctionnalité n\'est plus maintenue par les développeurs d\'origine, je ne peux donc plus la corriger.'
            },
            componentsDesc: {
                getVideoCaptionsTracksApiV3: 'Renvoie une liste de pistes de sous-titres associées à une vidéo spécifiée. Cette réponse API ne contient pas les sous-titres réels (utilisez plutôt DownloadVideoCaptionsApiV3)',
                downloadVideoCaptionsApiV3: 'Renvoie une liste de légendes associées à une vidéo spécifiée. Remarque: Cela ne fonctionne que pour vos propres vidéos ou pour les vidéos pour lesquelles les contributions de sous-titres tiers sont activées',
                getSubscriptionsApiV3: 'Renvoie une liste d\'abonnements en fonction des paramètres fournis',
                getCountApiValue: 'Méthode qui permet de créer des compteurs numériques simples à l\'aide de CountAPI',
                searchForPeopleApiV2: 'Méthode qui permet de rechercher des individus en fonction de leur nom de titre, de leur nom de personne ou de leur identifiant Netflix_ID unique à l\'aide de l\'API uNoGS (Unofficial Netflix Online Global Search) V2',
                searchForTitlesApiV2: 'Méthode qui permet d\'obtenir une large gamme d\'options de recherche de titres à l\'aide de l\'API uNoGS (Unofficial Netflix Online Global Search) V2',
                getListOfGenresApiV2: 'Méthode qui permet de récupérer une longue liste de genres à l\'aide de l\'API uNoGS (Unofficial Netflix Online Global Search) V2',
                getListOfSupportedCountriesApiV2: 'Méthode permettant d\'obtenir une liste à jour des pays pris en charge à l\'aide de l\'API uNoGS (Unofficial Netflix Online Global Search) V2'
            },
            signUp: {
                header: 'Inscription',
                createAccount: 'CRÉER UN COMPTE',
                username: 'Nom d\'utilisateur:',
                email: 'Courriel:',
                password: 'Mot de passe:',
                confirmPassword: 'Confirmation du mot de passe:',
                create: 'Créer',
                accountCreatedSuccessfully: 'Compte créé avec succès!'
            },
            login: {
                header: 'Connexion',
                email: 'Courriel:',
                password: 'Mot de passe:',
                btnLogin: 'Connexion',
                loginSuccessful: 'Connexion réussie',
                signUp: 'Vous n\'avez pas de compte? Créez-en un!',
            },
            checkout: {
                header: 'Débloquer les thèmes',
                darkThemeOnly: {
                    header: 'Thème sombre seulement',
                    subscriptionPrice: '1,50$CA/mois',
                },
                holidaysBundle: {
                    header: 'Ensemble des fêtes + Thème sombre',
                    moreInfo: 'Contient aussi tous les futurs styles des fêtes (ex: Saint Patrick)',
                    subscriptionPrice: '10,00$CA/mois',
                },
                preciousMetalsBundle: {
                    header: 'Métaux précieux',
                    subscriptionPrice: '7,50$CA/mois',
                },
                subscriptionOptions: 'Options d\'abonnements:',
                exceptions: {
                    header: 'Exceptions:',
                    holidaysBundleException: '*Certaines exceptions s\'appliquent',
                    holidaysThemesLimitation: '* Les thèmes de l\'abonnement "Métaux précieux" ne sont pas inclus dans cet abonnement.'
                },
                hugeThanks: 'Je tiens à remercier tous ceux qui supportent le projet!',
                comingSoon: 'À venir!',
                unlockThemes: 'Débloque les thèmes suivants:',
                themesDemo: 'Démonstration:'
            }
        }
    }
});

const app = createApp(App)
app.use(VueSweetalert2)
app.use(router)
app.use(i18n)
app.use(VueStripeMenu)
app.mount('#app')