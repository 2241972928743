<!-- Code souvent réutilisé dans plusieurs components -->
<script>
import UtilitiesLightTheme from '../../utilities/UtilitiesLightTheme';
import UtilitiesDarkTheme from '../../utilities/UtilitiesDarkTheme';

export default {
  name: 'ReusableCode',
  data() {
    return {
      dureePopup: 3000,
    }
  },
  created() {
    this.theme = localStorage.getItem('FPASCurrentTheme');
  },
  methods: {
    gestionThemes(typeBalise) {
      switch (typeBalise) {
        case 'border':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'projectBorderStylesDarkTheme';
            case 'FPASLightTheme': return 'projectBorderStylesLightTheme';
            case 'FPASHalloweenEventTheme': return 'projectBorderStylesHalloweenEvent';
            case 'FPASChristmasEventTheme': return 'projectBorderStylesChristmasEventGreen';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'projectBorderStylesSpecialGoldTheme';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'projectBorderStylesSpecialBronzeTheme';
            default: return 'projectBorderStylesLightTheme'
          }
        case 'borderDeprecatedWarning':
          return 'projectBorderStylesDeprecated'
        case 'texte':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'darkThemeTextColor';
            case 'FPASLightTheme': return 'lightThemeTextColor';
            case 'FPASHalloweenEventTheme': return 'halloweenEventTextColor';
            case 'FPASChristmasEventTheme': return 'christmasEventTextColor';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'goldSpecialThemeTextColor';
            case 'FPASSilverSpecialTheme-S1LV3R': return 'silverSpecialThemeTextColor';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'bronzeSpecialThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        case 'borderLink':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'darkThemeBorderLink';
            case 'FPASLightTheme': return 'lightThemeBorderLink';
            case 'FPASHalloweenEventTheme': return 'halloweenEventBorderLink';
            case 'FPASChristmasEventTheme': return 'christmasEventBorderLink';
            default: return 'lightThemeBorderLink'
          }
        case 'propTypeStyle':
            switch (this.theme) {
              case 'FPASDarkTheme': return 'propTypeStyleDarkTheme';
              case 'FPASLightTheme': return 'propTypeStyleLightTheme';
              case 'FPASHalloweenEventTheme': return 'propTypeStyleHalloweenEvent';
              case 'FPASChristmasEventTheme': return 'propTypeStyleChristmasEvent';
              case 'FPASGoldSpecialTheme-G0LD-V1P': return 'propTypeStyleSpecialGoldTheme';
              default: return 'propTypeStyleLightTheme'
            }
        case 'codeExample':
            switch (this.theme) {
              case 'FPASDarkTheme': return 'codeExampleDarkTheme';
              case 'FPASLightTheme': return 'codeExampleLightTheme';
              case 'FPASHalloweenEventTheme': return 'codeExampleHalloweenEvent';
              case 'FPASChristmasEventTheme': return 'codeExampleChristmasEvent';
              case 'FPASGoldSpecialTheme-G0LD-V1P': return 'codeExampleSpecialGoldTheme';
              default: return 'codeExampleLightTheme'
            }
        case 'hr':
            switch (this.theme) {
              case 'FPASDarkTheme': return 'hrDarkTheme';
              case 'FPASLightTheme': return 'hrLightTheme';
              case 'FPASHalloweenEventTheme': return 'hrHalloweenEventTheme';
              case 'FPASChristmasEventTheme': return 'hrChristmasEventTheme';
              default: return 'hrLightTheme'
            }
        case 'header':
            switch (this.theme) {
              case 'FPASDarkTheme': return 'headerDarkTheme';
              case 'FPASLightTheme': return 'headerLightTheme';
              case 'FPASHalloweenEventTheme': return 'headerHalloweenEvent';
              case 'FPASChristmasEventTheme': return 'headerChristmasEvent';
              case 'FPASGoldSpecialTheme-G0LD-V1P': return 'headerSpecialGoldTheme';
              case 'FPASSilverSpecialTheme-S1LV3R': return 'headerSpecialSilverTheme';
              case 'FPASBronzeSpecialTheme-BR0NZ3': return 'headerSpecialBronzeTheme';
              default: return 'lightThemeTextColor'
            }
        case 'icon':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'iconColorDarkTheme';
            case 'FPASLightTheme': return 'iconColorLightTheme';
            case 'FPASHalloweenEventTheme': return 'iconColorHalloweenEvent';
            case 'FPASChristmasEventTheme': return 'iconColorChristmasEvent';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'iconColorSpecialGoldTheme';
            case 'FPASSilverSpecialTheme-S1LV3R': return 'iconColorSpecialSilverTheme';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'iconColorSpecialBronzeTheme';
            default: return 'iconLightTheme'
          }
        case 'button':
          switch(this.theme) {
            case 'FPASDarkTheme': return 'buttonDarkTheme';
            case 'FPASLightTheme': return 'buttonLightTheme';
            case 'FPASHalloweenEventTheme': return 'buttonHalloweenEventTheme';
            case 'FPASChristmasEventTheme': return 'buttonChristmasEventTheme';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'buttonSpecialGoldTheme';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'buttonSpecialBronzeTheme';
            default: return 'buttonLightTheme';
          }
        case 'input':
          switch(this.theme) {
            case 'FPASDarkTheme': return 'inputDarkTheme';
            case 'FPASLightTheme': return 'inputLightTheme';
            case 'FPASHalloweenEventTheme': return 'inputHalloweenEventTheme';
            case 'FPASChristmasEventTheme': return 'inputChristmasEventTheme';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'inputSpecialGoldTheme';
            case 'FPASSilverSpecialTheme-S1LV3R': return 'inputSpecialSilverTheme';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'inputSpecialBronzeTheme';
            default: return 'inputLightTheme';
          }
        case 'borderAndText':
          switch (this.theme) {
            case 'FPASDarkTheme': return 'borderAndTextDarkTheme';
            case 'FPASLightTheme': return 'borderAndTextLightTheme';
            case 'FPASHalloweenEventTheme': return 'borderAndTextHalloweenEvent';
            case 'FPASChristmasEventTheme': return 'borderAndTextChristmasEvent';
            case 'FPASGoldSpecialTheme-G0LD-V1P': return 'borderAndTextSpecialGoldTheme';
            case 'FPASBronzeSpecialTheme-BR0NZ3': return 'borderAndTextSpecialBronzeTheme';
            default: return 'borderAndTextLightTheme'
          }
        default:
      }
    },
    showAlert(alertType, errorMessage) {
      switch (this.theme) {
          case 'FPASDarkTheme':
          case 'FPASHalloweenEventTheme':
            UtilitiesDarkTheme.CreerAlerteSwalNoQuestion(this.dureePopup, alertType, errorMessage, '');
            break;
          case 'FPASLightTheme':
          case 'FPASChristmasEventTheme':
            UtilitiesLightTheme.CreerAlerteSwalNoQuestion(this.dureePopup, alertType, errorMessage, '');
            break;
          default:
            UtilitiesLightTheme.CreerAlerteSwalNoQuestion(this.dureePopup, alertType, errorMessage, '');
            break;
        }
    },
    changementHref(composant) {
      if (composant !== undefined && composant !== '' && composant !== null) {
        localStorage.setItem('FPASTempPath', composant);
        this.$router.push({ path: composant })
      }
    },
    setMaxHeight(pageCourante) {
      var htmlElement = document.querySelector('html');
      switch (pageCourante) {
        case 'checkout':
          htmlElement.classList.add('maxHeightCheckout');
          htmlElement.classList.remove('maxHeight');
          break;
        default:
          htmlElement.classList.add('maxHeight');
          htmlElement.classList.remove('maxHeightCheckout');
      }
    }
  }
}
</script>