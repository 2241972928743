<template>
  <div />
</template>

<!-- Enlever le scoped applique l'effet, mais partout -->
<style scoped>
section {
    position:relative;
    width: 100%;
    height: 100%;
}

html::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100vh;
    animation: rain 0.2s ease-in-out infinite;
    background: url('../../../assets/img/rain.png');
    z-index: -10;
}

@keyframes rain {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 20% 100%;
    }
}
</style>