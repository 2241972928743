<template>
  <div v-show="contentLoaded">
    <footer style="height:47px">
      <hr :class="gestionThemes('hr')">
      <!-- Copyright -->
      <div>
        <p :class="gestionThemes('texte')" style="font-size:12px; padding:5px">{{ $t("sidebar.note.copyright", {}, { locale: $i18n.locale }) }}</p>
      </div>
    </footer>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import ReusableCode from './ReusableCode.vue';

export default {
  name: 'HeaderComponent',
  components: {
    ReusableCode
  },
  data() {
    return {
      theme: '',
      estMobile: false,
      contentLoaded: false,
    }
  },
  created() {
    this.theme = localStorage.getItem('FPASCurrentTheme');
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
    this.contentLoaded = true;
  },
  methods: {
    gestionThemes(styleBalise) {
      if (this.contentLoaded)
        return this.$refs.logic.gestionThemes(styleBalise);
    }
  }
}
</script>

<style scoped>
.mainLogo {
  width: 40%;
}
</style>