<template>
  <div>
    <Documentation
      :title="'SearchVideosApiV3'"
      :codeExample="'await fpasGoogleServices.SearchVideosApiV3(ytCredentials, searchApiV3);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'SearchVideosApiV3View',
  components: {
    Documentation
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'ytCredentials', type: 'YTCredentials', description: this.$t('propsDesc.youtube.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'searchApiV3', type: 'YTSearchApiV3', description: this.$t('propsDesc.youtube.searchApiV3Desc', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('searchVideosApiV3.description', {}, { locale: this.$i18n.locale });
  },
}
</script>