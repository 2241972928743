<template>
  <div>
    <Documentation
      :title="'SearchForTitlesApiV2'"
      :codeExample="'fpasNetflixServices.SearchForTitlesApiV2(credentials, search);'"
      :necessaryProps="necessaryProps"
      :description="desc"
      :isDeprecated="false"
    />
  </div>
</template>

<script>
import Documentation from '../../components/composantsGlobal/Documentation.vue';

export default {
  name: 'SearchForTitlesApiV2View',
  components: {
    Documentation
  },
  data() {
    return {
      necessaryProps: [],
      desc: '',
    };
  },
  created() {
    this.necessaryProps.push({ title: 'credentials', type: 'uNoGSNetflixCredentials', description: this.$t('propsDesc.netflix.credentialsDesc', {}, { locale: this.$i18n.locale })});
    this.necessaryProps.push({ title: 'search', type: 'FPASNetflixSearchForTitlesApiV2', description: this.$t('propsDesc.netflix.fPASNetflixSearchForTitlesApiV2', {}, { locale: this.$i18n.locale })});
    this.desc = this.$t('componentsDesc.searchForTitlesApiV2', {}, { locale: this.$i18n.locale });
  }
}
</script>