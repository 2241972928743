import { createWebHistory, createRouter } from 'vue-router';
import HomeView from '../views/HomeView';
// Connected user
import LoginView from '../views/connectedUser/login/LoginView';
import SignUpView from '../views/connectedUser/login/SignUpView';
import CheckoutView from '../views/connectedUser/CheckoutView';
// PayPal
import CreateAndSendInvoiceApiV2View from '../views/paypal/invoices/CreateAndSendInvoiceApiV2View';
import CreateInvoiceApiV2View from '../views/paypal/invoices/CreateInvoiceApiV2View';
import SendInvoiceApiV2View from '../views/paypal/invoices/SendInvoiceApiV2View';
import DeleteInvoiceApiV2View from '../views/paypal/invoices/DeleteInvoiceApiV2View';
import CancelSentInvoiceApiV2View from '../views/paypal/invoices/CancelSentInvoiceApiV2View';
import RefundInvoiceApiV2View from '../views/paypal/invoices/RefundInvoiceApiV2View';
import GetInvoiceDetailsApiV2View from '../views/paypal/invoices/GetInvoiceDetailsApiV2View';
import GenerateInvoiceQRCodeApiV2View from '../views/paypal/invoices/GenerateInvoiceQRCodeApiV2View';
import GenerateInvoiceNumberApiV2View from '../views/paypal/invoices/GenerateInvoiceNumberApiV2View';
import ListTransactionsApiV1View from '../views/paypal/transactions/ListTransactionsApiV1View';
// YouTube
import SearchVideosApiV3View from '../views/google/youtube/SearchVideosApiV3View';
import GetVideoReportReasonsApiV3View from '../views/google/youtube/GetVideoReportReasonsApiV3View';
import GetI18nLanguagesApiV3View from '../views/google/youtube/GetI18nLanguagesApiV3View';
import GetVideoCaptionsTracksApiV3View from '../views/google/youtube/GetVideoCaptionsTracksApiV3View';
import DownloadVideoCaptionsApiV3View from '../views/google/youtube/DownloadVideoCaptionsApiV3View';
import GetSubscriptionsApiV3View from '../views/google/youtube/GetSubscriptionsApiV3View';
//-------- MISC ------- //
// CountAPI
import GetCountApiValueView from '../views/misc/countApi/GetCountApiValueView';
// -------------------- //

// Netflix
import SearchForPeopleApiV2View from '../views/netflix/SearchForPeopleApiV2View';
import SearchForTitlesApiV2View from '../views/netflix/SearchForTitlesApiV2View';
import GetListOfGenresApiV2View from '../views/netflix/GetListOfGenresApiV2View';
import GetListOfSupportedCountriesApiV2View from '../views/netflix/GetListOfSupportedCountriesApiV2View';

const routes = [
    {
        path: '/', name: 'Home', component: HomeView,
    },
    // Connected user
    {
        path: '/Login', name: 'Login', component: LoginView,
    },
    {
        path: '/SignUp', name: 'SignUp', component: SignUpView,
    },
    {
        path: '/Checkout', name: 'Checkout', component: CheckoutView,
    },
    // PAYPAL
    {
        path: '/FPASPayPalServices/Invoices/CreateAndSendInvoiceApiV2', name: 'CreateAndSendInvoiceApiV2', component: CreateAndSendInvoiceApiV2View,
    },
    {
        path: '/FPASPayPalServices/Invoices/CreateInvoiceApiV2', name: 'CreateInvoiceApiV2', component: CreateInvoiceApiV2View
    },
    {
        path: '/FPASPayPalServices/Invoices/SendInvoiceApiV2', name: 'SendInvoiceApiV2', component: SendInvoiceApiV2View
    },
    {
        path: '/FPASPayPalServices/Invoices/DeleteInvoiceApiV2', name: 'DeleteInvoiceApiV2', component: DeleteInvoiceApiV2View
    },
    {
        path: '/FPASPayPalServices/Invoices/CancelSentInvoiceApiV2', name: 'CancelSentInvoiceApiV2', component: CancelSentInvoiceApiV2View
    },
    {
        path: '/FPASPayPalServices/Invoices/RefundInvoiceApiV2', name: 'RefundInvoiceApiV2', component: RefundInvoiceApiV2View
    },
    {
        path: '/FPASPayPalServices/Invoices/GetInvoiceDetailsApiV2', name: 'GetInvoiceDetailsApiV2', component: GetInvoiceDetailsApiV2View
    },
    {
        path: '/FPASPayPalServices/Invoices/GenerateInvoiceQRCodeApiV2', name: 'GenerateInvoiceQRCodeApiV2', component: GenerateInvoiceQRCodeApiV2View
    },
    {
        path: '/FPASPayPalServices/Invoices/GenerateInvoiceNumberApiV2', name: 'GenerateInvoiceNumberApiV2', component: GenerateInvoiceNumberApiV2View
    },
    {
        path: '/FPASPayPalServices/Transactions/ListTransactionsApiV1', name: 'ListTransactionsApiV1', component: ListTransactionsApiV1View
    },
    // YOUTUBE
    {
        path: '/FPASGoogleServices/YouTube/SearchVideosApiV3', name: 'SearchVideosApiV3', component: SearchVideosApiV3View
    },
    {
        path: '/FPASGoogleServices/YouTube/GetVideoReportReasonsApiV3', name: 'GetVideoReportReasonsApiV3', component: GetVideoReportReasonsApiV3View
    },
    {
        path: '/FPASGoogleServices/YouTube/GetI18nLanguagesApiV3', name: 'GetI18nLanguagesApiV3', component: GetI18nLanguagesApiV3View
    },
    {
        path: '/FPASGoogleServices/YouTube/GetVideoCaptionsTracksApiV3', name: 'GetVideoCaptionsTracksApiV3', component: GetVideoCaptionsTracksApiV3View
    },
    {
        path: '/FPASGoogleServices/YouTube/DownloadVideoCaptionsApiV3', name: 'DownloadVideoCaptionsApiV3', component: DownloadVideoCaptionsApiV3View
    },
    {
        path: '/FPASGoogleServices/YouTube/GetSubscriptionsApiV3', name: 'GetSubscriptionsApiV3', component: GetSubscriptionsApiV3View
    },
    // COUNT-API (MISC)
    {
        path: '/FPASMiscServices/CountAPI/GetCountApiValue', name: 'GetCountApiValue', component: GetCountApiValueView
    },
    // NETFLIX
    {
        path: '/FPASNetflixServices/SearchForPeopleApiV2', name: 'SearchForPeopleApiV2', component: SearchForPeopleApiV2View
    },
    {
        path: '/FPASNetflixServices/SearchForTitlesApiV2', name: 'SearchForTitlesApiV2', component: SearchForTitlesApiV2View
    },
    {
        path: '/FPASNetflixServices/GetListOfGenresApiV2', name: 'GetListOfGenresApiV2', component: GetListOfGenresApiV2View
    },
    {
        path: '/FPASNetflixServices/GetListOfSupportedCountriesApiV2', name: 'GetListOfSupportedCountriesApiV2', component: GetListOfSupportedCountriesApiV2View
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;