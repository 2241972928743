<template>
  <div :class="validationEspacement()">
    <div
      id="loginContent"
      :class="gestionThemes('border')"
      style="margin-top:30px;padding-top:10px"
    >
      <div style="text-align:center;justify-content: center;display: grid;margin:auto;padding-bottom:20px">
        <div>
          <p :class="gestionThemes('texte')" style="font-size:20px">
            <b>{{ $t("login.header", {}, { locale: $i18n.locale }) }}</b>
          </p><br>
        </div>
        <div style="display:flex">
          <label class="lengthLabel" :class="gestionThemes('texte')">{{ $t("login.email", {}, { locale: $i18n.locale }) }}</label>
          <input :class="gestionThemes('input')" id="txtLoginEmail" type="text">
        </div>
        <div style="display:flex">
          <label class="lengthLabel" :class="gestionThemes('texte')">{{ $t("login.password", {}, { locale: $i18n.locale }) }}</label>
          <input :class="gestionThemes('input')" id="txtLoginPassword" type="password">
        </div>
        <div style="padding:20px">
          <button :class="gestionThemes('button')" @click="login()">{{ $t("login.btnLogin", {}, { locale: $i18n.locale }) }}</button>
        </div>
        <a href="/SignUp" style="cursor:pointer" :class="gestionThemes('texte')" @click="changementHref('/SignUp')"><i>{{ $t("login.signUp", {}, { locale: $i18n.locale }) }}</i></a>
      </div>
    </div>
    <ReusableCode ref="logic" />
  </div>
</template>

<script>
import ReusableCode from '../../../components/composantsGlobal/ReusableCode.vue';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default {
  name: 'LoginPage',
  components: {
    ReusableCode
  },
  data() {
    return {
      estMobile: false,
      contentLoaded: false,
    }
  },
  created() {
    this.theme = localStorage.getItem('FPASCurrentTheme');
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
    this.contentLoaded = true;
    this.$nextTick(() => {
      this.contentStyle();
    })
  },
  methods: {
    validationEspacement() {
      if (this.contentLoaded) {
        if (!this.estMobile)
          return 'espacementDesktop';
        return '';
      }
    },
    gestionThemes(typeBalise) {
      if (this.contentLoaded)
        return this.$refs.logic.gestionThemes(typeBalise);
    },
    contentStyle() {
      if (this.contentLoaded) {
        var content = document.getElementById('loginContent');
        if (this.estMobile)
          content.classList.add('affichageContenuMobile');
        else
          content.classList.add('affichageContenu');
      }
    },
    showAlert(alertType, errorMessage) {
      return this.$refs.logic.showAlert(alertType, errorMessage);
    },
    changementHref(composant) {
      this.$refs.logic.changementHref(composant);
    },
    async login() {
      const errorType = 'error';

      // Email
      const email = document.getElementById('txtLoginEmail').value;
      if (email === '') {
        this.showAlert(errorType, this.$t('errors.signup.emailRequired',{},{ locale: this.$i18n.locale }));
        return;
      }

      // Password
      const password = document.getElementById('txtLoginPassword').value;
      if (password === '') {
        this.showAlert(errorType, this.$t('errors.login.passwordRequired',{},{ locale: this.$i18n.locale }));
        return;
      }

      const authentication = getAuth();

      // Test the login + redirect if that worked
      try {
        await signInWithEmailAndPassword(authentication, email, password).then((login) => {
          localStorage.setItem('FPASUserUID', login.user.uid);
          this.showAlert('success', this.$t('login.loginSuccessful',{},{ locale: this.$i18n.locale }));
          this.changementHref('/');
        })
      } catch (error) {
        let errorMessage = '';
        // Utilisateur introuvable ou mauvais mot de passe
        if (error.toString().includes('auth/user-not-found') || error.toString().includes('auth/wrong-password')) {
          errorMessage = this.$t('errors.login.invalidInput',{},{ locale: this.$i18n.locale });
        }
        this.showAlert(errorType, errorMessage);
      }
    }
  }
}
</script>

<style scoped>
.lengthLabel {
  width: 150px;
}
</style>