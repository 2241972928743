/* eslint-disable no-unused-vars */
import swal from 'sweetalert2';

const utilities = {
  CreerAlerteSwalNoQuestion(tempsMillis, icone, titre, description) {
    swal.fire({
      title: titre,
      text: description,
      icon: icone,
      timer: tempsMillis,
      position: 'top-start',
      toast: true,
      showConfirmButton: false,
      timerProgressBar: true,
      width: localStorage.getItem('FPASSwalLength')
    });
  },
}

export default utilities;